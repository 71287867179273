import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Grid from "@mui/material/Grid";

export const IgnoreLayout = () => {

    return (
        <Grid sx={{ display: 'flex', flexDirection: 'column', minHeight: "100vh"}}>
            <Header />
            <Outlet />
            <Footer />
        </Grid>
    )
};
import { Box, Container, Divider, Grid, Tab, Tabs, Typography } from "@mui/material";
import carouselInvesting from '../../assets/img/carousel-investing.jpg';
import carouselPricing from '../../assets/img/carousel-pricing.jpg';
import carouselProjects from '../../assets/img/carousel-projects.jpg';
import carouselRegister from '../../assets/img/carousel-register.jpg';
import carouselResults from '../../assets/img/carousel-results.jpg';
import carouselUploadFile from '../../assets/img/carousel-upload-file.jpg';
import carouselUploadProducts from '../../assets/img/carousel-upload-products.jpg';
import notFound from '../../assets/img/not-found.jpg';

import { motion } from "framer-motion";
import {useEffect, useState} from "react";
import LoginIcon from '@mui/icons-material/Login';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import PanToolAltOutlinedIcon from '@mui/icons-material/PanToolAltOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import i18n from "i18next";

const renderCollectionSwitch = (value) => {
    switch (value) {
        case 'supplier':
            return supplierInfoCollection;
        case 'enterprise':
            return enterpriseInfoCollection;
        case 'inversor':
            return inversorInfoCollection;
        default:
            return [];
    }
}

const renderActorName = (value) => {
    switch (value) {
        case 'supplier':
            return 'Home.supplier.subtitle';
        case 'enterprise':
            return 'Home.company.subtitle';
        case 'inversor':
            return 'Home.investor.subtitle';
    }
}

const fadeIn = (direction, delay) => {
    return {
        hidden: {
            y: direction === 'up' ? 80 : direction === 'down' ? -80 : 0,
            opacity: 0,

            x: direction === 'left' ? 80 : direction === 'right' ? -80 : 0,
        },
        show: {
            y: 0,
            x: 0,
            opacity: 1,
            transition: {
                type: 'tween',
                duration: 1.2,
                delay: delay,
                ease: [0.25, 0.25, 0.25, 0.75],
            },
        },
    };
};

const supplierInfoCollection = [
    {
        information: 'Home.supplier.sing_up.description',
        Icon: LoginIcon,
        img: carouselRegister
    },
    {
        information: 'Home.supplier.products.description',
        Icon: FindInPageOutlinedIcon,
        img: carouselUploadProducts
    },
    {
        information: 'Home.supplier.pricings.description',
        Icon: PanToolAltOutlinedIcon,
        img: carouselPricing
    }
]

const inversorInfoCollection = [
    {
        information: 'Home.investor.sing up.description',
        Icon: LoginIcon,
        img: carouselRegister
    },
    {
        information: 'Home.investor.observe.description',
        Icon: InventoryOutlinedIcon,
        img: carouselProjects
    },
    {
        information: 'Home.investor.invest.description',
        Icon: RequestQuoteOutlinedIcon,
        img: carouselInvesting
    }
]

const enterpriseInfoCollection = [
    {
        information: 'Home.company.sing_up.description',
        Icon: LoginIcon,
        img: carouselRegister
    },
    {
        information: 'Home.company.pricings.description',
        Icon: DescriptionOutlinedIcon,
        img: carouselUploadFile
    },
    {
        information: 'Home.company.suppliers.description',
        Icon: TaskOutlinedIcon,
        img: carouselResults
    }
]

export const Actors = () => {

    const [value, setValue] = useState("enterprise");

    const { t } = useTranslation();
    const savedLanguage = Cookies.get('language');
    useEffect(() => {
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, []);

    const onHandleChange = (e, value) => {
        setValue(value);
    }

    return (
        <Box component="section"
            sx={{
                backgroundColor: "background.neutral",
                height: {
                    xs: '100%'
                },
                padding: '2rem'
            }}
        >
            <Container>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item xs={12}>
                        <Box sx={{ width: '100%', marginBottom: 2 }}>
                            <Tabs
                                value={value}
                                onChange={onHandleChange}
                                aria-label="wrapped label tabs example"
                                centered
                            >
                                <Tab value="enterprise" label={t("Home.company.title")} />
                                <Tab
                                    value="supplier"
                                    label={t("Home.supplier.title")}
                                />
                                <Tab value="inversor" label={t("Home.investor.title")} />
                            </Tabs>
                        </Box>
                        <Typography textAlign="center" gutterBottom variant="h3" sx={{ fontWeight: 'bold' }}>
                            {t('Home.company.text')} <Box component="span" sx={{ color: 'primary.main' }}>
                                {t(renderActorName(value))}
                            </Box>?
                        </Typography>
                    </Grid>

                    {
                        renderCollectionSwitch(value).map(({ information, Icon, img }, idx) => (
                            <Grid
                                key={idx}
                                xs={12}
                                sm={4}
                                item
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                                component={motion.div}
                                variants={fadeIn('up', 0.4)}
                                initial='hidden'
                                whileInView={'show'}
                                viewport={{ once: true, amount: 0.2 }}
                            >
                                <Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            boxShadow: 1,
                                            marginBottom: 2
                                        }}
                                    >
                                        <img
                                            style={{
                                                width: 'auto',
                                                height: 'auto',
                                                maxWidth: '100%',
                                                maxHeight: '100%',
                                                padding: 0,
                                                margin: 0,
                                            }}
                                            src={img}>
                                        </img>
                                    </Box>
                                    <Box>
                                        <Divider>
                                            <Icon
                                                sx={{ color: "primary.main" }}
                                                fontSize="large"
                                            />
                                        </Divider>
                                    </Box>
                                    <Typography paragraph textAlign='center' color="text.secondary">
                                        {t(information)}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))
                    }


                </Grid>

            </Container>
        </Box >
    )
}
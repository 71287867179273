import {combineReducers, configureStore} from '@reduxjs/toolkit'
import productsReducer from './features/products/productsSlice'
import userReducer from './features/user/userSlice'
import pricingReducer from './features/pricing/pricingSlice'
import snackbarReducer from './features/ui/snackbarSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

const rootReducer = combineReducers({
    user: userReducer,
    products: productsReducer,
    pricing: pricingReducer,
    snackbar: snackbarReducer,
})

const persistConfig = {
    key: 'root',
    storage,
}
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk]
})

export const persistor = persistStore(store)
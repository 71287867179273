import { Box, Container, Grid, Typography } from "@mui/material";

import purposePricing from '../../assets/img/purpose-pricing.jpg';
import purposeNetwork from '../../assets/img/purpose-network.jpg';
import purposeInvesting from '../../assets/img/purpose-investing.jpg';
import purposeTransparency from '../../assets/img/purpose-transparency.jpg';
import { motion } from 'framer-motion';
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import {useEffect} from "react";
import i18n from "i18next";

const fadeIn = (direction, delay) => {
    return {
        hidden: {
            y: direction === 'up' ? 80 : direction === 'down' ? -80 : 0,
            opacity: 0,

            x: direction === 'left' ? 80 : direction === 'right' ? -80 : 0,
        },
        show: {
            y: 0,
            x: 0,
            opacity: 1,
            transition: {
                type: 'tween',
                duration: 1.2,
                delay: delay,
                ease: [0.25, 0.25, 0.25, 0.75],
            },
        },
    };
};

const infoCollection = [

    {
        title: 'Home.pricings.title',
        subtitle: 'Home.pricings.description',
        img: purposePricing
    },
    {
        title: 'Home.red.title',
        subtitle: 'Home.red.description',
        img: purposeNetwork
    },
    {
        title: 'Home.opportunities.title',
        subtitle: 'Home.opportunities.description',
        img: purposeInvesting
    },
    {
        title: 'Home.transparency.title',
        subtitle: 'Home.transparency.description',
        img: purposeTransparency
    }
]

const Purpose = () => {

    const { t } = useTranslation();
    const savedLanguage = Cookies.get('language');
    useEffect(() => {
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, []);

    return (
        <Box
            component="section"
            sx={{
                backgroundColor: "#FFF",
                display: "flex",
                flexDirection: 'row',
                alignItems: 'center',
                height: {
                    xs: '100%'
                },
                padding: '2rem'
            }}>
            <Container>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: 2
                    }}
                >
                    <Typography color="text.primary" gutterBottom variant="h3" sx={{ fontWeight: 'bold' }}>
                        {t('Home.proposal.title')}
                    </Typography>
                    <Typography paragraph textAlign="center" color="text.secondary">
                        {t('Home.proposal.description')}
                    </Typography>
                </Box>
                <Grid
                    container
                    spacing={4}
                >
                    {
                        infoCollection.map((information, idx) => (
                            <Grid
                                key={idx}
                                xs={12}
                                sm={6}
                                item
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                                component={motion.div}
                                variants={fadeIn('up', 0.4)}
                                initial='hidden'
                                whileInView={'show'}
                                viewport={{ once: true, amount: 0.2 }}
                            >
                                <Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            marginBottom: 1,
                                            maxHeight: '250px',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <img
                                            style={{
                                                width: 'auto',
                                                height: 'auto',
                                                maxWidth: '300px',
                                                maxHeight: '300px',
                                                padding: 0,
                                                margin: 0,
                                            }}
                                            src={information.img}>
                                        </img>
                                    </Box>
                                </Box>
                                <Typography color="text.primary" variant="h3" textAlign="center">
                                    {t(information.title)}
                                </Typography>

                                <Typography sx={{ color: 'text.secondary' }} textAlign="center">
                                    {t(information.subtitle)}
                                </Typography>
                            </Grid>
                        ))
                    }
                </Grid>
            </Container>
        </Box>

    );
};

export default Purpose;
import React, {useState} from "react";
import { TextField, Button, FormControl, FormLabel} from "@mui/material";

function validateEmail(email) {
    let emailString = String(email);
    if (emailString.length > 50){
        return false;
    }
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
}

//Returns true if a chilean rut number is valid, returns false otherwise
function validateRut(rawRut) {
    let rut = String(rawRut);
    if (rut.length > 15){
        return false;
    }
    rut = rut.replace(/[^0-9K]/g,'');
    if (rut.length !== 9) {
      return false;
    }
    let rutOnly = parseInt(rut.slice(0,-1));
    let verificationNumber = rut.slice(-1).toUpperCase();
    if (isNaN(rutOnly) || isNaN(verificationNumber)) {
      return false;
    }
    let sum = 0;
    let mul = 2;
    for (let i=rutOnly.toString().length-1;i>=0;i--) {
      sum += mul * parseInt(rutOnly.toString().charAt(i));
      mul++;
      if (mul === 8) mul = 2;
    }
    let mod11 = sum % 11;
    let calculatedVerificationNumber = (11 - mod11).toString();
    if (calculatedVerificationNumber === '10') calculatedVerificationNumber = 'K';
    if (calculatedVerificationNumber === '11') calculatedVerificationNumber = '0';
    if (calculatedVerificationNumber !== verificationNumber) {
      return false;
    }
    
    return true;
}

function validateOther(input, maxLength){
    if (input.length > maxLength || input.length === 0){
        return false;
    }
    else{
        return true;
    }
}

function EditProfileForm({ onFormSubmit }) {
    const [isNameValid, setIsNameValid] = useState(true);

    const [isLastNameValid, setIsLastNameValid] = useState(true);

    const [isRazonSocialValid, setIsRazonSocialValid] = useState(true);

    const [isDescriptionValid, setIsDescriptionValid] = useState(true);

    const [isEmailValid, setIsEmailValid] = useState(true);

    const [isRutEmpresaValid, setIsRutEmpresaValid] = useState(true);

    const [isRunRepresentanteValid, setIsRunRepresentanteValid] = useState(true);
    
    const [formData, setFormData] = useState({});
    function handleInputChange(event) {
        if (event.target.id === "correo"){
            setIsEmailValid(validateEmail(event.target.value));
            if (validateEmail(event.target.value)){
                setFormData({
                    ...formData,
                    [event.target.id]: event.target.value,
                });
            }
            else{
                const newFormData = { ...formData };
                delete newFormData[event.target.id];
                setFormData(newFormData);
            }
        }
        if (event.target.id === "RutEmpresa"){
            setIsRutEmpresaValid(validateRut(event.target.value));
            if (validateRut(event.target.value)){
                setFormData({
                    ...formData,
                    [event.target.id]: event.target.value,
                });
            }
            else{
                const newFormData = { ...formData };
                delete newFormData[event.target.id];
                setFormData(newFormData);
            }
        }
        if (event.target.id === "RunRepresentante"){
            setIsRunRepresentanteValid(validateRut(event.target.value));
            if (validateRut(event.target.value)){
                setFormData({
                    ...formData,
                    [event.target.id]: event.target.value,
                });
            }
            else{
                const newFormData = { ...formData };
                delete newFormData[event.target.id];
                setFormData(newFormData);
            }
        }
        if (event.target.id === "nombre"){
            const validInput = validateOther(event.target.value, 25);
            setIsNameValid(validInput);
            if (validInput){
                setFormData({
                    ...formData,
                    [event.target.id]: event.target.value,
                });
            }
            else{
                const newFormData = { ...formData };
                delete newFormData[event.target.id];
                setFormData(newFormData);
            }
        }
        if (event.target.id === "apellido"){
            const validInput = validateOther(event.target.value, 25);
            setIsLastNameValid(validInput);
            if (validInput){
                setFormData({
                    ...formData,
                    [event.target.id]: event.target.value,
                });
            }
            else{
                const newFormData = { ...formData };
                delete newFormData[event.target.id];
                setFormData(newFormData);
            }
        }
        if (event.target.id === "RazonSocial"){
            const validInput = validateOther(event.target.value, 15);
            setIsRazonSocialValid(validInput);
            if (validInput){
                setFormData({
                    ...formData,
                    [event.target.id]: event.target.value,
                });
            }
            else{
                const newFormData = { ...formData };
                delete newFormData[event.target.id];
                setFormData(newFormData);
            }
        }
        if (event.target.id === "descripcion"){
            const validInput = validateOther(event.target.value, 255);
            setIsDescriptionValid(validInput);
            if (validInput){
                setFormData({
                    ...formData,
                    [event.target.id]: event.target.value,
                });
            }
            else{
                const newFormData = { ...formData };
                delete newFormData[event.target.id];
                setFormData(newFormData);
            }
        }
        
    }
    function handleSubmit(event) {
        event.preventDefault();
        onFormSubmit(formData);
    }
    const inputStyle = {
        margin: '8px',
    };

    return (
        <form onSubmit={handleSubmit} noValidate autoComplete="off" style={{ display: 'flex', flexDirection: 'column'}} >
            <FormControl>
                <FormLabel>Modifica los datos de la empresa (No es necesario llenar todos los campos) </FormLabel>
                {isNameValid ?
                <TextField id="nombre" label="Nombre del representante" style={inputStyle} onChange={handleInputChange}/>
                : <TextField error id="nombre" label="Nombre del representante" style={inputStyle} onChange={handleInputChange}/>
                }
                {isLastNameValid?
                <TextField id="apellido" label="Apellido del representante" style={inputStyle} onChange={handleInputChange}/>
                : <TextField error id="apellido" label="Apellido del representante" style={inputStyle} onChange={handleInputChange}/>
                }
                {isRunRepresentanteValid ?
                <TextField id="RunRepresentante" label="RUN del Representante" style={inputStyle} onChange={handleInputChange}/>
                : <TextField error id="RunRepresentante" label="RUN del Representante" style={inputStyle} onChange={handleInputChange}/>
                }
                {isEmailValid ? 
                <TextField id="correo" label="Correo" style={inputStyle} onChange={handleInputChange}/>
                : <TextField error id="correo" label="Correo" style={inputStyle} onChange={handleInputChange}/>}
                {isRazonSocialValid ?
                <TextField id="RazonSocial" label="Razón social" style={inputStyle} onChange={handleInputChange}/>
                : <TextField error id="RazonSocial" label="Razón social" style={inputStyle} onChange={handleInputChange}/>
                }
                {isRutEmpresaValid ?
                <TextField id="RutEmpresa" label="RUT de la empresa" style={inputStyle} onChange={handleInputChange}/>
                : <TextField error id="RutEmpresa" label="RUT de la empresa" style={inputStyle} onChange={handleInputChange}/>}
                {isDescriptionValid ?
                <TextField id="descripcion" label="Descripción de la Empresa" style={inputStyle} onChange={handleInputChange}/>
                : <TextField error id="descripcion" label="Descripción de la Empresa" style={inputStyle} onChange={handleInputChange}/>
                }
                <Button type="submit" variant="contained" color="primary">
                Guardar Cambios
                </Button>
            </FormControl>
        </form>
    );
}

export default EditProfileForm;
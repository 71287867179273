import { createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
    name: 'user',
    initialState: {
        userID: null,
        userType: null,
        userEmail: null,
        userCorp: null,
    },
    reducers: {
        setUser: (state, action) => {
            state.userID = action.payload;
        },
        setUserType: (state, action) => {
            state.userType = action.payload;
        },
        setUserEmail: (state, action) => {
            state.userEmail = action.payload;
        },
        setUserCorp: (state, action) => {
            state.userCorp = action.payload;
        },
    }
})

export const {setUser, setUserType, setUserEmail, setUserCorp} = userSlice.actions;

export default userSlice.reducer;
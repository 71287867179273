import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    entities: {}
}

const productsSlice = createSlice({
    name: 'products',
    initialState: {
        productID: "",
    },
    reducers: {
        setProduct: (state, action) => {
            state.productID = action.payload;
        },
        productAdded(state, action) {
            const product = action.payload
            state.entities[product.id] = product
        },
        productDeleted(state, action) {
            const productId = action.payload
            delete state.entities[productId]
        },
        emptyProducts(state, action) {
            state.entities = {}
        }
    }
})

export const {setProduct, productAdded, productDeleted, emptyProducts } = productsSlice.actions

export default productsSlice.reducer
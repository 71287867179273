import { Box, Container, styled, Typography } from "@mui/material";
import React from "react";
import company_name from "../../assets/img/company_name.png";
import logosImg from "../../assets/img/logos.png";

const Sponsors = () => {
    const CustomContainer = styled(Container)(({ theme }) => ({
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            marginBottom: theme.spacing(4),
        },
    }));

    const CustomBox = styled(Box)(({ theme }) => ({
        [theme.breakpoints.down("md")]: {
            marginBottom: theme.spacing(4),
        },
    }));

    return (
        <Box sx={{ mt: 5 }}>
            <div
                style={{
                    width: "5%",
                    height: "5px",
                    backgroundColor: "#000339",
                    margin: "0 auto",
                    marginBottom: "5rem"
                }}
            ></div>
            <CustomContainer>
                <CustomBox>
                    <img src={company_name} alt="logo" style={{ maxWidth: "100%"}} />
                    <Typography
                        variant="body2"
                        sx={{
                            color: "#7D8589",
                            fontSize: "16px",
                            fontWeight: "bold",
                        }}
                    >
                        Respaldada por las mejores empresas del rubro
                    </Typography>
                </CustomBox>
            </CustomContainer>

            <Container sx={{ display: "flex", flexDirection: "column", alignItems: 'center'}}>
                <img src={logosImg} alt="logos" style={{maxHeight: "50%", maxWidth: "50%"}}/>
            </Container>
        </Box>
    );
};

export default Sponsors;
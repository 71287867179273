import { DataGrid, esES, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid'
import { useQuery } from '@tanstack/react-query'
import React, {useEffect, useState} from 'react'
import { getPricings } from '../../../../../lib/api/pricings';
import { Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, Checkbox, Chip, ClickAwayListener, Container, Divider, Fade, Grid, Grow, IconButton, LinearProgress, Link, ListItem, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popper, Stack, Tooltip, Typography, Zoom } from '@mui/material';
import { Link as RouterLink, useParams } from 'react-router-dom';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { OptionsButton } from '../../components/companies/CompanyOptionsButton';
import { format as formatRut } from 'rut.js'
import FoundationTwoToneIcon from '@mui/icons-material/FoundationTwoTone';
import PercentTwoToneIcon from '@mui/icons-material/PercentTwoTone';
import PaidTwoToneIcon from '@mui/icons-material/PaidTwoTone';
import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useModals } from '../../../../../features/ui/ModalContext';
import { CancelPricingForm } from './CancelPricingForm';
import TroubleshootTwoToneIcon from '@mui/icons-material/TroubleshootTwoTone';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { ConfirmSuppliersForm } from './ConfirmSuppliersForm';
import { formatDate } from '../../../../../lib/utils/dates';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import MapIcon from '@mui/icons-material/Map';
import PlaceIcon from '@mui/icons-material/Place';
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import i18n from "i18next";

export const CompanyPricingPage = () => {

    const { t } = useTranslation();
    const savedLanguage = Cookies.get('language');
    useEffect(() => {
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, []);

    const { pricingId } = useParams();
    const { push } = useModals();

    const [selectedRows, setSelectedRows] = useState([]);

    const { data, isLoading, isSuccess, isError, error } = useQuery({ queryKey: ['pricings', pricingId], queryFn: () => getPricings(pricingId) });
    
    const handleCancelPricing = () => {
        push(
            <CancelPricingForm
                pricingData={{
                    ...data?.data
                }}
            />
        );
    }

    const columns = [
        { 
            field: 'name', 
            headerName: t('pricing.suppliers.company'),
            flex: 0.5, 
            minWidth: 150,
            renderCell: (params) => {
                return (
                    <Link
                        component="button"
                        onClick={() => handleDetails(params.value)}
                    >
                        {params.value.user.corporation.name}
                    </Link>
                )
            }
        },
        /*{
            field: 'status',
            headerName: 'Estado',
            flex: 0.3,
            renderCell: ({ value }) => {

                return (
                    <>
                        {
                            // (!value.selected && !value.accepted) &&  <Chip label="Chip Filled" />
                            !value.selected && !value.accepted &&
                            <Tooltip title="Puedes seleccionar el siguiente proveedor">
                                <Chip label="Esperando selección" />
                            </Tooltip>
                        }
                        {
                            // (!value.selected && !value.accepted) &&  <Chip label="Chip Filled" />
                            value.selected && !value.accepted &&
                            <Tooltip title="El proveedor aún no confirma la cotización">
                                <Chip label="Esperando confirmación" />
                            </Tooltip>
                        }
                        {
                            value.selected && value.accepted &&
                            <Tooltip title="El proveedor ha confirmado la cotización">
                                <Chip label="Confirmado" color='success' />
                            </Tooltip>
                        }
                    </>
                )

            }
        },*/
        {
            field: 'coverage_percentage',
            headerName: t('pricing.description.coverage'),
            flex: 0.3,
            minWidth: 50,
        },
        {
            field: 'products',
            headerName: t('products_company.title'),
            flex: 0.3,
            minWidth: 50,
            sortable: false,
            renderCell: (params) => {
                return (
                    <IconButton onClick={() => handleProducts(params.value)} aria-label="delete" size="medium">
                        <FindInPageIcon fontSize="inherit" />
                    </IconButton>
                )
            }
        },
        /*{
            minWidth: 100,
            flex: 0.2,
            ...GRID_CHECKBOX_SELECTION_COL_DEF
        },*/

    ];

    const handleDetails = (details) => {
        push(
            <Card sx={{ minWidth: 350 }}>
                <CardHeader
                    sx={{ textAlign: 'center' }}
                    title={details.user.corporation.name}
                    subheader={formatRut(details.user.corporation.rut)}
                    action={
                        <Tooltip title={t('pricing.suppliers.click.web')}>
                            <IconButton component="a" href={details?.user?.corporation?.website ? `//${details?.user?.corporation?.website}` : '#'} rel="noopener noreferrer" target="_blank" aria-label="link to web">
                                <Avatar>
                                    <OpenInNewIcon />
                                </Avatar>
                            </IconButton>
                        </Tooltip>
                    }
                />
                <CardContent>
                    <ListItem>
                        <ListItemIcon>
                            <MapIcon color='primary' />
                        </ListItemIcon>
                        <ListItemText primary={t('pricing.suppliers.click.adress')} secondary={details.user.corporation.contact.location.address} />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <PlaceIcon color='primary' />
                        </ListItemIcon>
                        <ListItemText primary={t('pricing.suppliers.click.region')} secondary={`${details.user.corporation.contact.location.region}, ${details.user.corporation.contact.location.city}.`} />
                    </ListItem>
                    {
                        //details.status.selected && details.status.accepted &&
                        <>
                            <Divider sx={{ marginY: 2 }}><Chip label={t('pricing.suppliers.click.text')} /></Divider>
                            <ListItem>
                                <ListItemIcon>
                                    <LocalPhoneIcon color='primary' />
                                </ListItemIcon>
                                <ListItemText primary={t('pricing.suppliers.click.phone')} secondary={details.user.corporation.contact.phone} />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <EmailIcon color='primary' />
                                </ListItemIcon>
                                <ListItemText primary={t('pricing.suppliers.click.Email')} secondary={details.user.corporation.contact.email} />
                            </ListItem>
                        </>
                    }
                </CardContent>

            </Card>
        )
    }

    const handleProducts = (products) => {
        push(
            <Card sx={{ minWidth: 350 }}>
                <CardHeader
                    sx={{ textAlign: 'center' }}
                    title={t('pricing.suppliers.products.title')}
                    subheader={t('pricing.suppliers.products.subtitle')}
                />
                <CardContent>
                    <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
                        {products.map(product => <Chip key={product._id} label={product.name} />)}
                    </Stack>
                </CardContent>

            </Card>
        )
    }

    const rows = Boolean(data?.data?.suppliers) ? data?.data?.suppliers?.map(supplier => ({
        id: supplier.user._id,
        name: supplier,
        coverage_percentage: Math.round(supplier.quantities.matching_products_ratio * 10000) / 100,
        products: supplier.matched_products,
        status: supplier.status,
        selected: supplier.status.selected
    })) : [];




    const handleRowSelection = (params) => {
        setSelectedRows(params);
    };

    const handleConfirmSuppliers = () => {
        push(
            <ConfirmSuppliersForm pricingId={pricingId} suppliersData={data.data.suppliers} selectedSuppliersIds={[...selectedRows]} />
        )
    }

    // ## Validaciones ##
    if (isError){
        if (error.response?.status === 400)
            return (
                <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
                    <Card sx={{ padding: 5 }}>
                        <Typography variant="h3">{t('pricing.error')}</Typography>
                    </Card>
                </Box>
            )
        if (error.response?.status === 403)
            return (
                <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
                    <Card sx={{ padding: 5 }}>
                        <Typography variant="h3">{t('pricing.error2')}</Typography>
                    </Card>
                </Box>
            )
        else
            return (
                <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
                    <Card sx={{ padding: 5 }}>
                        <Typography variant="h3">{t('pricing.error3')}</Typography>
                    </Card>
                </Box>
            )
    }

    if (isLoading) return <LinearProgress />
    // ## Validaciones ##

    return (
        <Container
            maxWidth="lg"
            sx={{ marginY: 2 }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginY: 3
                }}
            >
                <Button component={RouterLink} to="/app/pricings" variant="outlined" startIcon={<ArrowBackIosIcon />}>{t('pricing.back')}</Button>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginY: 3
                }}
            >
                <Typography variant="h4">
                    {t('pricing.title')} <Box component="span" sx={{ color: 'primary.main' }}>{data?.data?.title}</Box>
                </Typography>
            </Box>

            <Card sx={{ padding: 2 }}>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        xs={12}
                        md={6}
                        lg={3}
                    >
                        <Stack alignItems="center" spacing={0.5}>
                            <FoundationTwoToneIcon sx={{ color: 'primary.main' }} fontSize='large' />
                            <Typography variant='subtitle2' color="#979797" >{t('pricing.description.type')}</Typography>
                            <Typography variant='subtitle1'>{data?.data?.constructionType}</Typography>
                        </Stack>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        lg={3}
                    >
                        <Stack alignItems="center" spacing={0.5}>
                            <PaidTwoToneIcon sx={{ color: 'primary.main' }} fontSize='large' />
                            <Typography variant='subtitle2' color="#979797" >{t('pricing.description.budget')}</Typography>
                            <Typography variant='subtitle1'>{data?.data?.budgetPrice} {data?.data?.currency}</Typography>
                        </Stack>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        lg={3}
                    >
                        <Stack alignItems="center" spacing={0.5}>
                            <PercentTwoToneIcon sx={{ color: 'primary.main' }} fontSize='large' />
                            <Typography variant='subtitle2' color="#979797" >{t('pricing.description.percentage')}</Typography>
                            <Typography variant='subtitle1'>{data?.data?.fundingPercentage}%</Typography>
                        </Stack>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        lg={3}
                    >
                        <Stack alignItems="center" spacing={0.5}>
                            <CalendarMonthTwoToneIcon sx={{ color: 'primary.main' }} fontSize='large' />
                            <Typography variant='subtitle2' color="#979797" >{t('pricing.description.created')}</Typography>
                            <Typography variant='subtitle1'>{formatDate(data?.data?.createdAt)}</Typography>
                        </Stack>
                    </Grid>

                    {/*<Grid
                        item
                        xs={12}
                        lg={2}
                    >
                        <Stack alignItems="center" spacing={0.5}>
                            <TroubleshootTwoToneIcon sx={{ color: 'primary.main' }} fontSize='large' />
                            <Typography variant='subtitle2' color="#979797" >Estado</Typography>
                            <Box sx={{ display: 'flex', gap: 0.5 }}>
                                {
                                    data?.data?.status?.created && <Fade in={!!data?.data?.status?.created}><Chip size='small' variant="outlined" label="Creado" /></Fade>
                                }
                                {
                                    data?.data?.status?.expired && <Fade in={!!data?.data?.status?.expired}><Chip size='small' variant="outlined" label="Expirado" /></Fade>
                                }
                                {
                                    data?.data?.status?.processed && <Fade in={!!data?.data?.status?.processed}><Chip size='small' variant="outlined" color="info" key="processed" label="Procesada" /></Fade>
                                }
                                {
                                    data?.data?.status?.cancelled && <Fade in={!!data?.data?.status?.cancelled}><Chip size='small' variant="outlined" color="error" key="cancelled" label="Cancelado" /></Fade>
                                }
                            </Box>
                        </Stack>
                    </Grid>*/}
                </Grid>
            </Card>

            <Grid sx={{ marginY: 2 }} container gap={2} justifyContent="flex-end">
                {/*<Button disabled={!!data?.data?.status?.cancelled} onClick={handleCancelPricing} variant="contained" color='error'>Cancelar cotización</Button>*/}
                <Button component={RouterLink} to={`/app/products/${pricingId}`} variant="contained">
                    {t('pricing.products2')}
                </Button>
            </Grid>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginY: 3
                }}
            >
                <Typography variant="h4">
                    {t('pricing.suppliers.title')}
                </Typography>
            </Box>
            <Box sx={{ height: 650, width: '100%' }}>
                {
                    (!data && isLoading) &&
                    <LinearProgress />
                }
                {
                    isSuccess && data &&
                    <DataGrid
                        isRowSelectable={(params) => params.row.selected !== true}
                        onSelectionModelChange={handleRowSelection}
                        //checkboxSelection={true}
                        disableSelectionOnClick
                        loading={isLoading}
                        localeText={{
                            ...esES.components.MuiDataGrid.defaultProps.localeText,
                            noRowsLabel: t('pricing.searching')
                        }}
                        rows={rows}
                        columns={columns}
                        pageSize={100}
                        rowsPerPageOptions={[100]}
                    />
                }
            </Box>
            {/*<Grid sx={{ marginY: 2 }} container gap={2} justifyContent="flex-end">
                <Button
                    disabled={
                        selectedRows.length === 0 || !!data?.data?.status?.cancelled
                    }
                    onClick={handleConfirmSuppliers}
                    variant="contained"
                    color='success'>
                    Confirmar proveedores
                </Button>
            </Grid>*/}
        </Container>
    )
}

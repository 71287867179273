import { Alert, AlertTitle, Button, Card, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Slider, Table, TableBody, TableCell, TableRow, TextField, Tooltip, Typography, Zoom, useRadioGroup } from '@mui/material';
import { Box, Container, Stack } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SendIcon from '@mui/icons-material/Send';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { Link as RouterLink } from 'react-router-dom';
import { RadioSelect } from '../../../../components/forms/RadioSelect';
import { ProductForm } from './ProductForm';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import i18n from "i18next";



export const CreateProduct = () => {

    const { t } = useTranslation();
    const savedLanguage = Cookies.get('language');
    useEffect(() => {
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, []);

    const user = useSelector(state => state.user);
    const supplierId = user?.userID;


    return (
        <Container
            sx={{
                padding: '1rem',
                height: '100%',
                marginY: 3
            }}
            maxWidth="lg"
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Typography variant="h4">
                    {t('create_products.title')}
                </Typography>
                <Button component={RouterLink} to={`/app/suppliers/${supplierId}/products`} variant="outlined" startIcon={<ArrowBackIosIcon />}>{t('create_products.back')}</Button>

            </Box>
            <Box sx={{ marginTop: 2, marginBottom: 4 }} />
            <Divider sx={{ marginY: 2 }}></Divider>
            <Grid
                container
                spacing={5}
            >
                <Grid
                    item
                    xs={12}
                >
                    <Alert severity="info">
                        <AlertTitle>{t('create_products.information.title')}</AlertTitle>
                        <span dangerouslySetInnerHTML={{ __html: t('create_products.information.subtitle') }} />
                    </Alert>
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    <Typography variant="h6" gutterBottom>
                        {t('create_products.new.title')}
                    </Typography>

                    <ProductForm />

                </Grid>
            </Grid>
        </Container>
    )
}


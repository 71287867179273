import { DataGrid, esES } from '@mui/x-data-grid'
import { useQuery } from '@tanstack/react-query'
import React, {useEffect} from 'react'
import { getPricings } from '../../../../../lib/api/pricings';
import { Box, Button, Chip, Container, Fade, Grid, Grow, IconButton, LinearProgress, MenuItem, MenuList, Paper, Popper, Stack, Typography, Zoom } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { CompanyOptionsButton } from './CompanyOptionsButton';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { useSelector } from 'react-redux';
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import i18n from "i18next";


export const CompanyPricingsPage = () => {

    const { t } = useTranslation();
    const savedLanguage = Cookies.get('language');
    useEffect(() => {
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, []);

    const { data, isLoading, isSuccess } = useQuery({ queryKey: ['pricings'], queryFn: () => getPricings() });

    const user = useSelector(state => state.user);

    const columns = [
        {
            field: 'title', headerName: t('pricings_lists.name'), flex: 1, minWidth: 150,
            renderCell: (params) => {
                return <Link component={RouterLink} to={`/app/pricings/${params.id}`} > {params.value}</Link >
            }
        },
        /*{
            field: 'status',
            headerName: 'Estado',
            renderCell: (params) => {
                let elements = [];
                params.value.created && elements.push(<Fade key="created" in={!!params.value.created}><Chip variant="outlined" label="Creado" /></Fade>);
                params.value.processed && elements.push(<Fade key="processed" in={!!params.value.processed}><Chip variant="outlined" color="info" key="processed" label="Procesada" /></Fade>);
                params.value.cancelled && elements.push(<Fade key="cancelled" in={!!params.value.cancelled}><Chip variant="outlined" color="error" key="cancelled" label="Cancelado" /></Fade>);
                params.value.expired && elements.push(<Fade key="expired" in={!!params.value.expired}><Chip variant="outlined" color="warning" key="expired" label="Expirado" /></Fade>);
                return <Stack direction="row" gap={1}>{elements}</Stack>
            },
            minWidth: 370,
            flex: 1
        },*/
        {
            field: 'date', headerName: t('pricings_lists.date'),
            renderCell: (params) => {
                const date = new Date(params.value);
                return format(date, "dd 'de' MMMM 'del' yyyy", { locale: es });
            },
            flex: 0.8,
            minWidth: 250
        },
        /*{
            field: 'options', headerName: 'Opciones', renderCell: (params) => {
                return (
                    <CompanyOptionsButton pricingData={params.value} />
                )
            },
            minWidth: 100,
            flex: 0.5
        }*/
    ];

    const rows = Boolean(data?.data) ? data?.data.map(pricing => ({
        id: pricing._id,
        title: pricing.title,
        status: pricing.status,
        date: pricing.createdAt,
        options: pricing
    })) : [];

    return (
        <Container
            maxWidth="lg"
            sx={{ marginY: 2 }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginY: 3
                }}
            >
                <Typography variant="h4">
                    {t('pricings_lists.title')}
                </Typography>
                <Button component={RouterLink} to="create" variant="contained" startIcon={<AddShoppingCartIcon />}>{t('pricings_lists.create')}</Button>
            </Box>
            <Grid item xs={12} sx={{ height: 650, width: '100%' }}>
                {
                    (!data && isLoading) &&
                    <LinearProgress />
                }
                {
                    isSuccess && data &&
                    <DataGrid
                        loading={isLoading}
                        localeText={{
                            ...esES.components.MuiDataGrid.defaultProps.localeText,
                            noRowsLabel: 'No hay cotizaciones creadas aún'
                        }}
                        rows={rows}
                        columns={columns}
                        pageSize={100}
                        rowsPerPageOptions={[100]}
                    />
                }

            </Grid>
        </Container>
    )
}

import { useQuery } from '@tanstack/react-query'
import React, {useEffect, useState} from 'react'
import { getPricings } from '../../../../../lib/api/pricings';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Checkbox, Chip, ClickAwayListener, Container, Fade, Grid, Grow, IconButton, LinearProgress, Link, ListItem, ListItemText, MenuItem, MenuList, Paper, Popper, Stack, Tooltip, Typography, Zoom } from '@mui/material';
import { Link as RouterLink, useParams } from 'react-router-dom';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { format } from 'date-fns';
import { format as formatRut } from 'rut.js'
import { es } from 'date-fns/locale';
import FoundationTwoToneIcon from '@mui/icons-material/FoundationTwoTone';
import PercentTwoToneIcon from '@mui/icons-material/PercentTwoTone';
import PaidTwoToneIcon from '@mui/icons-material/PaidTwoTone';
import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useModals } from '../../../../../features/ui/ModalContext';
import TroubleshootTwoToneIcon from '@mui/icons-material/TroubleshootTwoTone';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { formatDate } from '../../../../../lib/utils/dates';
import { ConfirmPricingForm } from './ConfirmPricingForm';
import { useSelector } from 'react-redux';
import Chat from '../../components/Chat';
import dataProvider from "../../../../../lib/DataProvider";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import i18n from "i18next";
export const SupplierPricingPage = () => {

    const { t } = useTranslation();
    const savedLanguage = Cookies.get('language');
    useEffect(() => {
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, []);

    const { pricingId } = useParams();
    const { push } = useModals();
    const { userID: userId } = useSelector(state => state.user);

    const { data, isLoading, isSuccess, isError, error } = useQuery({ queryKey: ['suppliersPricings', pricingId], queryFn: () => getPricings(pricingId) });

    const [data2, setData2] = useState(null);

    const handleConfirmPricing = () => {
        push(
            <ConfirmPricingForm pricingData={data.data} supplierId={userId} />
        )
    }
    if (!data && !data2 && isLoading) {
        return <LinearProgress />
    }

    dataProvider.get(`/chats/conversations/${pricingId}/${userId}/${data.data.owner}`).then((data2) => {
        // response.data is an object that we can convert to an array
        if (data2 && data2.data) {
            setData2(data2.data);

        }
    }).catch((error) => {
        console.log(error);
    });

    if (!data2 || isLoading) {
        return <LinearProgress />;
    }

    // ## Validaciones ##
    if (isError){
        if (error.response?.status === 400)
            return (
                <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
                    <Card sx={{ padding: 5 }}>
                        <Typography variant="h3">{t('pricing.error')}</Typography>
                    </Card>
                </Box>
            )
        if (error.response?.status === 403)
            return (
                <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
                    <Card sx={{ padding: 5 }}>
                        <Typography variant="h3">{t('pricing.error2')}</Typography>
                    </Card>
                </Box>
            )
        else
            return (
                <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
                    <Card sx={{ padding: 5 }}>
                        <Typography variant="h3">{t('pricing.error3')}</Typography>
                    </Card>
                </Box>
            )
    }

    if (isLoading) return <LinearProgress />
    // ## Validaciones ##

    return (
        <Container
            maxWidth="lg"
            sx={{ marginY: 2 }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginY: 3
                }}
            >
                <Button component={RouterLink} to="/app/pricings" variant="outlined" startIcon={<ArrowBackIosIcon />}>{t('pricing.back')}</Button>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginY: 3
                }}
            >
                <Typography variant="h4">
                    {t('pricing.title')} <Box component="span" sx={{ color: 'primary.main' }}>{data?.data?.title}</Box>
                </Typography>
            </Box>

            <Card sx={{ padding: 2 }}>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        xs={12}
                        md={6}
                        lg={3}
                    >
                        <Stack alignItems="center" spacing={0.5}>
                            <FoundationTwoToneIcon sx={{ color: 'primary.main' }} fontSize='large' />
                            <Typography variant='subtitle2' color="#979797" >{t('pricing.description.type')}</Typography>
                            <Typography variant='subtitle1'>{data?.data?.constructionType}</Typography>
                        </Stack>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        lg={3}
                    >
                        <Stack alignItems="center" spacing={0.5}>
                            <PaidTwoToneIcon sx={{ color: 'primary.main' }} fontSize='large' />
                            <Typography variant='subtitle2' color="#979797" >{t('pricing.description.budget')}</Typography>
                            <Typography variant='subtitle1'>{data?.data?.budgetPrice} {data?.data?.currency}</Typography>
                        </Stack>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        lg={3}
                    >
                        <Stack alignItems="center" spacing={0.5}>
                            <PercentTwoToneIcon sx={{ color: 'primary.main' }} fontSize='large' />
                            <Typography variant='subtitle2' color="#979797" >{t('pricing.description.percentage')}</Typography>
                            <Typography variant='subtitle1'>{data?.data?.fundingPercentage}%</Typography>
                        </Stack>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        lg={3}
                    >
                        <Stack alignItems="center" spacing={0.5}>
                            <CalendarMonthTwoToneIcon sx={{ color: 'primary.main' }} fontSize='large' />
                            <Typography variant='subtitle2' color="#979797" >{t('pricing.description.created')}</Typography>
                            <Typography variant='subtitle1'>{formatDate(data?.data?.createdAt)}</Typography>
                        </Stack>
                    </Grid>

                    {/*<Grid
                        item
                        xs={12}
                        lg={2}
                    >
                        <Stack alignItems="center" spacing={0.5}>
                            <TroubleshootTwoToneIcon sx={{ color: 'primary.main' }} fontSize='large' />
                            <Typography variant='subtitle2' color="#979797" >Estado</Typography>
                            <Box sx={{ display: 'flex', gap: 0.5 }}>
                                {
                                    data?.data?.status?.created &&
                                    <Fade in={!!data?.data?.status?.created}>
                                        <Chip size='small' variant="outlined" label="Creado" />
                                    </Fade>
                                }
                                {
                                    data?.data?.status?.expired && <Fade in={!!data?.data?.status?.expired}><Chip size='small' variant="outlined" label="Expirado" /></Fade>
                                }
                                {
                                    data?.data?.status?.processed &&
                                    <Fade in={!!data?.data?.status?.processed}>
                                        <Chip size='small' variant="outlined" color="info" key="processed" label="Procesada" />
                                    </Fade>
                                }
                                {
                                    data?.data?.status?.cancelled &&
                                    <Fade in={!!data?.data?.status?.cancelled}>
                                        <Tooltip title="La empresa canceló la cotización">
                                            <Chip size='small' variant="outlined" color="error" key="cancelled" label="Cancelado" />
                                        </Tooltip>
                                    </Fade>
                                }
                            </Box>
                        </Stack>
                    </Grid>*/}
                </Grid>
            </Card>

            <Grid sx={{ marginY: 2 }} container gap={2} justifyContent="flex-end">
                {/*<Button disabled={!!data?.data?.status?.cancelled} onClick={handleConfirmPricing} variant="contained" color='success'>Confirmar cotización</Button>*/}
                <Button component={RouterLink} to={`/app/products/${pricingId}`} variant="contained">
                    {t('pricing.products')}
                </Button>
            </Grid>
            <Chat
                idPricing={pricingId}
                users={[
                    data.data.owner,
                    userId
                ]}
                sender={data2?.sender}
                recipient={data2?.recipient}
                conversation={data2?.conversation}
            />
        </Container>
    )
}
import React from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { connect , useDispatch } from 'react-redux';
import { closeSnackbar } from '../features/ui/snackbarSlice';

const SnackbarAlert = ({ open, message, severity }) => {

    const [isOpen, setIsOpen] = React.useState(open);

    const dispatch = useDispatch();

    React.useEffect(() => {
        setIsOpen(open);
    }, [open]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsOpen(false);
        dispatch(closeSnackbar());
    };

    return (
        <Snackbar
            open={isOpen}
            autoHideDuration={3500}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    );
}

const mapStateToProps = (state) => {
    return {
        open: state.snackbar.open,
        message: state.snackbar.message,
        severity: state.snackbar.severity
    };
};

export default connect(mapStateToProps)(SnackbarAlert);

import { Box, IconButton, InputAdornment, InputLabel, TextField } from "@mui/material";
import { useState } from "react";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

export const PasswordInput = ({ label = 'inputLabel', register, name, errors, rules = {} }) => {

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Box>
            <InputLabel
                id={label}
                sx={{
                    marginBottom: 1
                }}
            >
                {label}
            </InputLabel>
            <TextField
                type={showPassword ? 'text' : 'password'}
                id={label}
                // label="Contraseña"
                fullWidth
                {...register(`${name}`, rules)}
                error={!!errors[name]}
                helperText={errors[name]?.message}
                InputProps={{
                    endAdornment:
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                        </InputAdornment>
                }}
            />
        </Box>
    )
}

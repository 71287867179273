import { useEffect, useState } from 'react';
import { TextField, Button, Box, IconButton, Container, Card, Stack, Grid, InputLabel, Divider, Chip, InputAdornment, Grow, Dialog, Fade, Zoom } from '@mui/material';
import { Link, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { validate, clean, format } from 'rut.js'
import '../config';
import dataProvider from "../lib/DataProvider";
import { useDispatch } from 'react-redux';
import { setUser } from '../features/user/userSlice';
import { openSnackbar } from '../features/ui/snackbarSlice';
import { useForm, Controller } from 'react-hook-form';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { RadioSelect } from '../components/forms/RadioSelect';
import { RegionAndCitySelect } from '../components/forms/RegionAndCitySelect';
import { PasswordInput } from '../components/forms/PasswordInput';
import { green } from '@mui/material/colors';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import i18n from "i18next";
const SignUp = () => {

    const { t } = useTranslation();
    const savedLanguage = Cookies.get('language');
    useEffect(() => {
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, []);

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const [successfullLogin, setSuccessfullLogin] = useState(false);
    const [successfullData, setSuccessfullData] = useState({});

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { register, trigger, handleSubmit, watch, control, setValue, getValues, formState: { errors, touchedFields } } = useForm({ mode: 'onBlur' });


    const accountTypeOptions = [
        {
            value: 'company',
            title: t('register.type.company'),
        },
        {
            value: 'supplier',
            title: t('register.type.supplier'),
        },
        /*{
            value: 'investor',
            title: 'Inversionista',
        }*/
    ]
    const onSubmit = ({ confirmPassword, ...data }) => {

        dataProvider.post('/auth/register', {
            name: data.representativeName,
            lastname: data.representativeLastName,
            email: data.email,
            password: data.password,
            accountType: data.accountType,
            corporation: {
                website: data.website,
                name: data.companyName,
                rut: clean(data.companyRUT),
                contact: {
                    ...data.contact,
                    location: data.location
                }
            }
        }).then((data) => {
            // dispatch(openSnackbar({ "message": 'Registro exitoso', "severity": 'success' }));
            setSuccessfullLogin(true);
            setSuccessfullData(data);
        }).catch((error) => {
            dispatch(openSnackbar({ "message": error.message, "severity": 'error' }));
        });
    }

    const formatRut = (e) => {
        setValue(e.target.name, format(e.target.value));
    }

    useEffect(() => {
        if (touchedFields.confirmPassword) {
            trigger('confirmPassword')
        }
    }, [watch('password')], touchedFields.confirmPassword);

    if (successfullLogin) {
        return (
            <Grow in={successfullLogin}>
                <Container
                    sx={{
                        padding: '1rem',
                        height: 'calc(100vh - 24px)',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}
                    maxWidth="sm"
                >
                    <Box sx={{
                        padding: 2,
                        marginY: 5,
                        boxShadow: 5,
                        height: {
                            xs: '80vh',
                            lg: 'calc(50vh - 24px)'
                        }
                    }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center',
                                justifyContent: 'space-between',
                                gap: 5,
                                height: '100%'
                            }}
                        >

                            <Stack spacing={1}>
                                <Typography variant='h3' color="primary.main">¡Registro exitoso! &#x1F973;</Typography>
                                <Typography variant='h5' color="text.secondary">  Tu cuenta <Box color="text.primary" display='inline'>{successfullData.data?.email || ''}</Box> se ha registrado correctamente.</Typography>
                            </Stack>
                            <Box
                                sx={{
                                    bgcolor: '#4caf5050',
                                    borderRadius: '50%',
                                    width: 150,
                                    height: 150,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <CheckCircleOutlinedIcon
                                    sx={{
                                        fontSize: 160,
                                        color: "#4caf50",
                                    }}
                                />
                            </Box>
                            <Button component={Link} to="/login" sx={{ fontSize: 18 }}>Iniciar sesión</Button>

                        </Box>
                    </Box>
                </Container>
            </Grow>
        )
    }

    return (
        <Container
            sx={{
                marginY: '1rem',
                padding: '1rem',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}
            maxWidth="md"
        >
            <Card
                elevation={2}
            >
                <Stack
                    padding={2}
                    sx={{
                        gap: 3
                    }}
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Typography variant="h4" color="primary.main" textAlign="center">
                        {t('register.title')}
                    </Typography>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                            lg={3.5}
                        >
                            <InputLabel
                                id="representativeName"
                                sx={{
                                    marginBottom: 1
                                }}
                            >
                                {t('register.name')}
                            </InputLabel>
                            <TextField
                                type="text"
                                id='representativeName'
                                fullWidth
                                {...register("representativeName", {
                                    required: t('login.required')
                                })}
                                error={!!errors.representativeName}
                                helperText={errors.representativeName?.message}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            lg={3.5}
                        >
                            <InputLabel
                                id="representativeLastName"
                                sx={{
                                    marginBottom: 1
                                }}
                            >
                                {t('register.lastname')}
                            </InputLabel>
                            <TextField
                                type="text"
                                id='representativeLastName'
                                fullWidth
                                {...register("representativeLastName", {
                                    required: t('login.required')
                                })}
                                error={!!errors.representativeLastName}
                                helperText={errors.representativeLastName?.message}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            lg={5}
                        >
                            <InputLabel
                                id="email"
                                sx={{
                                    marginBottom: 1
                                }}
                            >
                                {t('register.mail')}
                            </InputLabel>
                            <TextField
                                type="email"
                                id='email'
                                // label="Email"
                                fullWidth
                                placeholder={t('login.placeholder')}
                                {...register("email", {
                                    required: t('login.required'),
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: t('login.requiredmessage')
                                    }
                                })}
                                error={!!errors.email}
                                helperText={errors.email?.message}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={6}
                        >
                            <PasswordInput
                                register={register}
                                errors={errors}
                                label={t('register.password')}
                                name="password"
                                rules={{
                                    required: t('login.required')
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={6}
                        >
                            <PasswordInput
                                register={register}
                                errors={errors}
                                label={t('register.password2')}
                                name="confirmPassword"
                                rules={{
                                    required: t('login.required'),
                                    validate: (val) => {
                                        if (watch('password') != val) {
                                            return t('register.errorpassword');
                                        }
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <RadioSelect
                                selectsContainerStyles={{
                                    display: 'flex',
                                    flexDirection: {
                                        xs: 'column',
                                        lg: 'row'
                                    },
                                    justifyContent: 'space-between',
                                    gap: 2
                                }}
                                name="accountType"
                                label={t('register.type.title')}
                                register={register}
                                options={accountTypeOptions}
                                errors={errors}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            lg={4}
                        >
                            <InputLabel
                                id="companyName"
                                sx={{
                                    marginBottom: 1
                                }}
                            >
                                {t('register.corporate_name')}
                            </InputLabel>
                            <TextField
                                type="text"
                                id='companyName'
                                fullWidth
                                placeholder={t('register.placeholder')}
                                {...register("companyName", {
                                    required: t('login.required')
                                })}
                                error={!!errors.companyName}
                                helperText={errors.companyName?.message}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            lg={4}
                        >
                            <Controller
                                name="companyRUT"
                                control={control}
                                rules={{
                                    required: t('login.required'),
                                    validate: (value, formValues) => validate(value) || 'Este no es un RUT valido'
                                }}
                                render={({ field }) => (
                                    <>
                                        <InputLabel
                                            id="companyRUT"
                                            sx={{
                                                marginBottom: 1
                                            }}
                                        >
                                            {t('register.rut')}
                                        </InputLabel>
                                        <TextField
                                            {...field}
                                            inputProps={{
                                                maxLength: 15
                                            }}
                                            type="text"
                                            id='companyRUT'
                                            fullWidth
                                            placeholder="12345678-9"
                                            onChange={formatRut}
                                            value={getValues('companyRUT') || ''}
                                            error={!!errors.companyRUT}
                                            helperText={errors.companyRUT?.message}
                                        />
                                    </>
                                )}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            lg={4}
                        >
                            <InputLabel
                                id="website"
                                sx={{
                                    marginBottom: 1
                                }}
                            >
                                {t('register.web')}
                            </InputLabel>
                            <TextField
                                type="text"
                                id='website'
                                fullWidth
                                {...register("website", {
                                    pattern: {
                                        value: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
                                        message: t('register.error')
                                    }
                                })}
                                error={!!errors.website}
                                helperText={errors.website?.message}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Divider>{t('register.contact.title')}</Divider>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            lg={6}
                        >
                            <InputLabel
                                id="contact.phone"
                                sx={{
                                    marginBottom: 1
                                }}
                            >
                                {t('register.contact.phone')}
                            </InputLabel>
                            <TextField
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">+56</InputAdornment>,
                                }}
                                type="tel"
                                id='contact.phone'
                                placeholder='912345678'
                                fullWidth
                                {...register("contact.phone", {
                                    required: t('login.required'),
                                    pattern: {
                                        value: /^(\+?56)?(\s?)(0?9)(\s?)[98765432]\d{7}$/,
                                        message: t('register.error')
                                    },
                                    setValueAs: (v) => `+56${v}`
                                })}
                                error={!!errors.contact?.phone}
                                helperText={errors.contact?.phone?.message}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            lg={6}
                        >
                            <InputLabel
                                id="location.address"
                                sx={{
                                    marginBottom: 1
                                }}
                            >
                                {t('register.contact.address')}
                            </InputLabel>
                            <TextField
                                type="text"
                                id='location.address'
                                // label="Email"
                                fullWidth
                                placeholder={t('register.placeholder2')}
                                {...register("location.address", {
                                    required: t('login.required')
                                })}
                                error={!!errors.location?.address}
                                helperText={errors.location?.address?.message}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <RegionAndCitySelect
                                setValue={setValue}
                                control={control}
                                errors={errors}
                                watch={watch}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                size="large"
                                type="submit"
                            >
                                {t('register.enter')}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Divider
                                sx={{
                                    color: "text.secondary",
                                    marginBottom: 1
                                }}
                            >
                                {t('register.text2')}
                            </Divider>
                            <Typography paragraph textAlign="center" color="text.primary">
                                {t('register.text3')}
                                <Link to={'/login'}>
                                    {t('register.text4')}
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </Stack>
            </Card>
        </Container>
    );
}
export default SignUp;
import dataProvider from "../DataProvider";

export const createPricing = (formData) => {
    return dataProvider.post('/pricings', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

export const getPricings = (pricingId = '') => {
    const endpoint = pricingId ? `/pricings/${pricingId}` : '/pricings';
    return dataProvider.get(endpoint);
}

export const updatePricing = (pricingId, data) => {
    return dataProvider.put(`/pricings/${pricingId}`, data);
}
import { createSlice } from '@reduxjs/toolkit'
import dataProvider from "../../lib/DataProvider";

const pricingSlice = createSlice({
    name: 'pricing',
    initialState: {
        name: "",
        imageURL: "",
        totalPrice: "",
        unitPrice: "",
        fundingPercentage: "",
        interestRate: "",
        products: [],
        supplier: ""
    },
    reducers: {
        setInitial: (state, action) => {
            state.name = action.payload.name;
            state.imageURL = action.payload.imageURL;
            state.totalPrice = action.payload.totalPrice;
            state.unitPrice = action.payload.unitPrice;
            state.fundingPercentage = action.payload.fundingPercentage;
            return state;
        },
        updateCSVData: (state, action) => {
            state.products = action.payload;
        },
        createPricing: async (state,action) => {
            await dataProvider.post('/pricings/', 
            {name: state.name, imageURL: state.imageURL, totalPrice: state.totalPrice, 
            currency: state.unitPrice, fundingPercentage: state.fundingPercentage,
            products: state.products});
        }
    }
})

export const {setInitial, updateCSVData, createPricing} = pricingSlice.actions;

export default pricingSlice.reducer;
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import {useEffect} from "react";
import i18n from "i18next";

export const formatRole = (role, t) => {

    switch (role) {
        case 'supplier':
            return t('profile_company.user.suppliername');
        case 'company':
            return t('profile_company.company.companyname')
        case 'inversor':
            return t('profile_company.user.investor');
        default: 
            return ''
    }
}
import { createContext, useContext } from 'react'

export const ModalContext = createContext({
    push(node) { },
})

export const useModals = () => {
    const actions = useContext(ModalContext)
    return actions
}

import { LinearProgress, Card, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { CreateProduct} from '../components/CreateProduct';
import { Box } from '@mui/system'


export const CreateProductsPage = () => {

    const user = useSelector(state => state.user);

    if (!user) return <LinearProgress />

    if (user.userType === 'supplier'){
        return <CreateProduct />
    }

    if (user.userType === 'company'){
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
                <Card sx={{ padding: 5 }}>
                    <Typography variant="h3">Debes ser un Proveedor para agregar productos</Typography>
                </Card>
            </Box>
        ) 
    }
}

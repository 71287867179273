import axios from 'axios';
import Cookies from 'js-cookie';

const dataProvider = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Authorization': process.env.REACT_APP_API_TOKEN,
        'Content-Type': 'application/json'
    }
});

// Add authorization header if logged in
dataProvider.interceptors.request.use((config) => {
    const token = Cookies.get('session_token');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
});
export default dataProvider;
import React, {useEffect} from 'react';
import { Grid, Typography, Link, Button, TextField, IconButton, Container } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import logo_ebim from "../assets/img/logo_without_bg.png";
import PlaceIcon from '@mui/icons-material/Place';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import i18n from "i18next";

export default function Footer() {

    const { t } = useTranslation();
    const savedLanguage = Cookies.get('language');
    useEffect(() => {
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, []);

    return (
        <Box
            component="footer"
            bottom={0}
            sx={{
                backgroundColor: '#E6F0FF', py: 4, textAlign: 'center', width: "100%", boxShadow: 1, marginTop: 'auto'
            }}
        >
            <Container
                sx={{
                    padding: '2rem'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: {
                            xs: 'center',
                            lg: 'space-between'
                        },
                        flexDirection: {
                            xs: 'column',
                            lg: 'row'
                        },
                        alignItems: {
                            xs: 'center',
                            lg: 'flex-start'
                        },
                        textAlign: 'center',
                        gap: 2
                    }}
                >
                    <Stack
                        spacing={2}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}

                        >
                            <img src={logo_ebim} alt="logo_ebim" width={"40px"} />
                            <Typography
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                                textAlign="center"
                                variant="h6"

                            >
                                E-BIMINDUSTRIAL
                            </Typography>
                        </Box>
                        <Box
                            component="span"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1
                            }}
                            color="text.secondary"
                        >
                            <PlaceIcon
                                sx={{ color: "primary.main" }}
                            />
                            Santiago, Chile
                        </Box>
                        <Box
                            component="span"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1
                            }}
                            color="text.secondary"
                        >
                            <EmailIcon
                                sx={{ color: "primary.main" }}
                            />
                            contacto@e-bimindustrial.com
                        </Box>
                    </Stack>
                    <Stack
                        sx={{
                            height: '100%'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start'
                            }}

                        >
                            {/*<Typography variant="h6" color="text.primary" gutterBottom>
                                Sobre nosotros
                            </Typography>*/}
                        </Box>
                    </Stack>

                    <Stack>
                        <Typography variant="h6" color="text.primary" gutterBottom>
                            {t('Home.footer.find us')}
                        </Typography>
                        <Stack direction="row" justifyContent="flex-end">
                            <Link href="https://www.linkedin.com/company/e-bimindustrial" color="inherit">
                                <LinkedInIcon
                                    sx={{ color: 'primary.main' }}
                                />
                            </Link>
                            <Link href="https://www.instagram.com/e.bimindustrial" color="inherit">
                                <InstagramIcon
                                    sx={{ color: 'primary.main' }}
                                />
                            </Link>
                        </Stack>
                    </Stack>
                </Box >
            </Container>
        </Box >

    );
}


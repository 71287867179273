import { Box, Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, LinearProgress, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { updatePricing } from "../../../../../lib/api/pricings";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useModals } from "../../../../../features/ui/ModalContext";

export const CancelPricingForm = ({ pricingData }) => {

    const queryClient = useQueryClient();
    const { handleClose } = useModals();

    const mutation = useMutation({
        mutationFn: () => {
            return updatePricing(pricingData._id, { status: { ...pricingData.status, cancelled: true } });
        },
        onMutate: (values) => {
            //Returned values ​​are passed to context
        },
        onSuccess: (result, variables, context) => {
            queryClient.invalidateQueries(['pricings']);
            handleClose();
        },
        onError: (result, variables, context) => {
            //show error
        },
    })

    return (
        <Box>
            <DialogTitle>
                Confirmación
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    ¿Estas seguro de cancelar la cotización <Box component="span" fontWeight='fontWeightMedium'>{pricingData.title}</Box>?
                </DialogContentText>
                {
                    mutation.isLoading && <Box sx={{ marginY: 2 }}><LinearProgress /></Box>
                }
            </DialogContent>
            <DialogActions sx={{
                gap: 2
            }}>
                <Button onClick={handleClose} color="info">
                    Volver
                </Button>
                <Button
                    onClick={mutation.mutate}
                    color="error"
                    disabled={mutation.isLoading}
                >
                    Cancelar cotización
                </Button>
            </DialogActions>
        </Box>
    )
}

import { Box, Button, Grid, InputLabel, Table, TableBody, TableCell, TableRow, TextField } from "@mui/material";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {useEffect, useState} from "react";
import Papa from 'papaparse';
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { uploadProducts } from "../../../../lib/api/suppliers";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import i18n from "i18next";

export const UploadCsvFile = ({ name, label = 'inputLabel', errors, register, inputProps, buttonProps, setCsvData }) => {

    const { t } = useTranslation();
    const savedLanguage = Cookies.get('language');
    useEffect(() => {
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, []);

    const [file, setFile] = useState(null);
    const [data, setData] = useState([]);

    const handleFileChange = (e) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
            Papa.parse(e.target.files[0], {
                skipEmptyLines: true,
                complete: (results) => {
                    setData(results.data);
                    setCsvData(results.data);
                }
            })
        }
    };

    return (
        <>
            <InputLabel
                id="file-input"
                sx={{
                    marginBottom: 1
                }}
            >
                {label}
            </InputLabel>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <TextField
                    id="outlined-read-only-input"
                    value={!!file ? file.name : t('create_products.nofile')}
                    InputProps={{
                        readOnly: true,
                        style: {
                            borderRadius: '4px 0px 0px 4px',
                        }
                    }}
                    sx={{
                        flexGrow: 3,
                    }}
                    error={!!errors.file}
                    helperText={errors.file?.message}
                />
                <Box
                    sx={{
                        flexGrow: 1
                    }}
                >
                    <label htmlFor="file-upload"
                        style={{
                            width: '100%'
                        }}
                    >
                        <input
                            style={{ display: "none" }}
                            id="file-upload"
                            type="file"
                            {...inputProps}
                            {...register(`${name}`, {
                                required: t('create_products.required'),
                                onChange: handleFileChange
                            })}
                        />
                        <Button
                            fullWidth
                            variant="contained"
                            color='secondary'
                            sx={{
                                height: 56,
                                borderRadius: '0px 4px 4px 0px',
                            }}
                            startIcon={<FileUploadIcon />}
                            component="span"
                            {...buttonProps}
                        >
                            {t('create_products.new.upload')}
                        </Button>
                    </label>
                </Box>
            </Box>
        </>
    )
}

// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en.json';
import translationES from './locales/es.json';
import translationPT from './locales/pt.json';
import dataProvider from "./lib/DataProvider";

const languages = {};


dataProvider.get(`/languages/es`).then((dataen) => {
    languages.en = {english: dataen.data}
}).catch((error) => {
    console.log(error);
});

dataProvider.get(`/languages/en`).then((dataes) => {
    languages.es = {spanish: dataes.data}
}).catch((error) => {
    console.log(error);
});

dataProvider.get(`/languages/pt`).then((datapt) => {
    languages.pt = {portuguese: datapt.data}
}).catch((error) => {
    console.log(error);
});



// the translations
const resources = {
  en: {
    translation: { ...translationEN, ...languages.en },
  },
  es: {
    translation: { ...translationES, ...languages.es },
  },
  pt: {
      translation: { ...translationPT, ...languages.pt }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'es', // default language
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
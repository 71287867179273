import { LinearProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { ProductForm } from './components/ProductForm';
import { CreateProductPage } from './components/CreateProduct';
import { Products } from './components/Products';

export const ProductsPage = () => {

    const user = useSelector(state => state.user);

    if (!user) return <LinearProgress />

    if (user.userType === 'company'){
        return <Products />
    }

    if (user.userType === 'supplier'){
        return <Products />
    }
}
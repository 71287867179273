import { Box, IconButton, Menu, MenuItem, Modal, Typography } from "@mui/material";
import { useState } from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useDispatch } from "react-redux";
import { useModals } from "../../../../../features/ui/ModalContext";
import { CancelPricingForm } from "./CancelPricingForm";
import { Link } from 'react-router-dom';


export const CompanyOptionsButton = ({ pricingData }) => {

    const { push } = useModals();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        setAnchorEl(null);
    };

    const notImplemented = () => {
        push(<Box sx={{ margin: 4 }}>WIP</Box>);
    }

    const handleCancelPricing = () => {
        push(<CancelPricingForm pricingData={pricingData} />);
    }

    return (
        <div>
            <IconButton
                id="menu-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                < MoreVertIcon />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'menu-button',
                }}
            >

                <MenuItem component={Link} to={`/app/products/${pricingData._id}`}>Ver productos</MenuItem>
                <MenuItem component={Link} to={`./${pricingData._id}`}>Ver proveedores</MenuItem>
                <MenuItem onClick={handleCancelPricing}>Cancelar cotización</MenuItem>
            </Menu>
        </div>
    )
}

module.exports = global.config = {
    i18n: {
        welcome: {
            en: "Welcome",
            fa: "خوش آمدید"
        }
        // rest of your translation object
    },
    // other global config variables you wish
    logState: {
        in: false,
        display: "block",
    },
    pathHistory: {
        path: "/",
    },
};
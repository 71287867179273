import { Card, CardMedia, CardContent, Container, Typography, Grid, Avatar, Button, TextField, LinearProgress, Box, Stack, Paper, Chip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Divider } from "@mui/material";
import { generateRow } from "../../components/profile/RowDisplay.jsx";
import { generateProjectCard } from "../../components/profile/ProjectCardDisplay.jsx";
import EditProfileForm from '../../components/profile/EditProfileForm.jsx';
import dataProvider from "../../lib/DataProvider.js";
import { format } from 'rut.js';
import { useParams } from "react-router-dom";
import { setUserType } from "../../features/user/userSlice";
import { useDispatch, useSelector } from 'react-redux';
import { openSnackbar } from '../../features/ui/snackbarSlice.js';
import { formatRole } from "../../lib/utils/formatRole.js";
import { useForm } from "react-hook-form";
import { RegionAndCitySelect } from "../../components/forms/RegionAndCitySelect.jsx";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import i18n from "i18next";

/**
 * ProfileEmpresa page component
 * @param {*} props 
 * @returns {JSX.Element}
 */
const Profile = () => {

    const { t } = useTranslation();
    const savedLanguage = Cookies.get('language');
    useEffect(() => {
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, []);

    const user = useSelector(state => state.user);
    const [data, setData] = useState(null);

    const { register, trigger, handleSubmit, watch, control, setValue, getValues, formState: { errors, touchedFields } } = useForm({
        defaultValues: {
        }
    });

    const dispatch = useDispatch();

    useEffect(() => {
        // Here we will retrieve the data from the backend and set it to the formData state variable.
        dataProvider.get('/users/' + user.userID).then((response) => {
            setData(response.data);
        }).catch((error) => {
            dispatch(openSnackbar({ "message": t('profile_company.error') + error.message, "severity": 'error' }));
        });
    }, [user]);

    useEffect(() => {
        setValue('location.region', data?.corporation?.contact?.location?.region, { shouldValidate: true });
        setValue('location.city', data?.corporation?.contact?.location?.city);
    }, [data]);

    if (!data) {
        return <LinearProgress />
    }

    return (
        <Container
            maxWidth="lg"
            sx={{
                marginY: 4
            }}
            component={Stack}
            spacing={2}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '1.5rem'
                }}
            >
                <Typography variant="h4">
                    {t('profile_company.user.title')}
                </Typography>

            </Box>

            <Grid
                container
                component={Paper}
                variant="outlined"
                padding={3}
            >
                <Grid
                    item
                    xs={12}
                    md={6}
                    padding={1}
                >
                    <Typography variant="h6" marginBottom={1}>{t('profile_company.user.title')}</Typography>
                    <Typography variant="p" color="text.secondary" marginBottom={1}>{t('profile_company.user.subtitle')} {formatRole(user.userType, t)}</Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    padding={1}
                >
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={6}
                        >
                            <TextField
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                                label={t('profile_company.user.name')}
                                defaultValue={data.name}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={6}
                        >
                            <TextField
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                                label={t('profile_company.user.lastname')}
                                defaultValue={data.lastname}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                        >
                            <TextField
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                                label={t('profile_company.user.mail')}
                                defaultValue={data.email}
                            />
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>

            <Grid
                container
                component={Paper}
                variant="outlined"
                padding={3}
            >
                <Grid
                    item
                    xs={12}
                    md={6}
                    padding={1}
                >
                    <Typography variant="h6" marginBottom={1}>{t('profile_company.company.title')}</Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    padding={1}
                >
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={6}
                        >
                            <TextField
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                                label={t('profile_company.company.companyname')}
                                defaultValue={data.corporation.name}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={6}
                        >
                            <TextField
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                                label={t('profile_company.company.rut')}
                                defaultValue={format(data.corporation.rut)}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                        >
                            <Divider><Chip label={t('profile_company.company.text')} /></Divider>
                        </Grid>
                        <Grid
                            item
                            xs={6}
                        >
                            <TextField
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                                label={t('profile_company.company.website')}
                                defaultValue={data.corporation?.website}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={6}
                        >
                            <TextField
                                InputProps={{
                                    readOnly: true,
                                }}
                                type="tel"
                                fullWidth
                                label={t('profile_company.company.phone')}
                                defaultValue={data.corporation?.contact.phone}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                        >
                            <Divider><Chip label={t('profile_company.company.text2')} /></Divider>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                        >
                            <TextField
                                InputProps={{
                                    readOnly: true,
                                }}
                                type="text"
                                fullWidth
                                label={t('profile_company.company.address')}
                                defaultValue={data.corporation?.contact?.location?.address}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                        >
                            <RegionAndCitySelect
                                CitySelectInputProps={{
                                    readOnly: true
                                }}
                                RegionSelectInputProps={{
                                    readOnly: true
                                }}
                                setValue={setValue}
                                control={control}
                                errors={errors}
                                watch={watch}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )

}

export default Profile;

import { Card, CardMedia, CardContent, CardHeader, Container, Typography, Grid, Avatar, Button } from "@mui/material";

/**
 * Generates a row element with the according fields.
 * @param {*} name 
 * @param {*} value 
 * @returns 
 */
export function generateRow(name, value) {
    return (
        <Row name={name} value={value} />
    );
}



/**
 * Row component for Profile display type pages.
 * @param {*} props 
 *  props.name: name of the row
 *  props.value: value of the row
 * @returns {JSX.Element}
 */
export function Row(props) {
    return (
        <>
        <Grid item xs={12} md={6}>
            <Typography style={{fontWeight: "bold"}} gutterBottom component="div">
            {props.name}:
            </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
            <Typography gutterBottom component="div">
            {props.value}
            </Typography>
        </Grid>
        </>
    );
}

export default Row;
import React, {useEffect} from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { Avatar, Card, CardActions, CardContent, Divider, Grid, IconButton } from "@mui/material";
import { Container } from "@mui/system";
import Paper from '@mui/material/Paper';
import SebastianIMG from '../../assets/img/sebastian.png';
import RodrigoLIMG from '../../assets/img/rodrigol.png';
import AngelaIMG from '../../assets/img/angela.png';
import DavidIMG from '../../assets/img/david.png';
import RodrigoFIMG from '../../assets/img/rodrigof.png';
import NicolasIMG from '../../assets/img/nicolas.png';
import LorenaIMG from '../../assets/img/lorena.png';
import CamiloIMG from '../../assets/img/camilo.jpeg';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import i18n from "i18next";



const teamInfoCollection = [
    {
        name: 'Sebastian Antiman',
        img: SebastianIMG,
        role: 'CEO',
        email: 'sebastian.antiman@e-bimindustrial.com',
        linkedin: 'https://www.linkedin.com/in/santimang/',
    },
    {
        name: 'Ángela Espinosa',
        img: AngelaIMG,
        role: 'COO',
        email: '',
        linkedin: 'https://www.linkedin.com/in/angela-espinosa-campos-670585120',
    },
    {
        name: 'Rodrigo Llull',
        img: RodrigoLIMG,
        role: 'CTO',
        email: 'rodrigo.llull@e-bimindustrial.com',
        linkedin: 'https://www.linkedin.com/in/rodrigo-llull-torres/',
    },
    {
        name: 'Nicolás Klein',
        img: NicolasIMG,
        role: 'CIO',
        email: 'nicolas.klein@e-bimindustrial.com',
        linkedin: 'https://www.linkedin.com/in/nicolaskleing',
    },
    {
        name: 'Lorena Gutiérrez',
        img: LorenaIMG,
        role: 'CSO',
        email: '',
        linkedin: 'https://www.linkedin.com/in/lorenagutierrezarab',
    },
    {
        name: 'Rodrigo Fuentes',
        img: RodrigoFIMG,
        role: 'Home.organization.RodrigoF.position',
        email: '',
        linkedin: 'https://www.linkedin.com/in/rodrigo-andrés-fuentes-4a679426',
    },
    {
        name: 'David Michea',
        img: DavidIMG,
        role: 'Home.organization.David.position',
        email: '',
        linkedin: 'https://www.linkedin.com/in/davidestebanmicheasalinas',
    },
    {
        name: 'Camilo Bravo',
        img: CamiloIMG,
        role: 'Home.organization.Camilo.position',
        email: 'camilo.bravo@e-bimindustrial.com',
        linkedin: 'https://www.linkedin.com/in/camilo-b-bravo-orlandini-8148839b/',
    },
]

const Team = () => {

    const { t } = useTranslation();
    const savedLanguage = Cookies.get('language');
    useEffect(() => {
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, []);

    return (
        <Box
            component="section"
            sx={{
                backgroundColor: 'background.default',
                padding: 2,
                height: '100%',
            }}
        >
            <Container
                sx={{
                    height: '100%',
                    display: "block",
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: 2
                    }}
                >
                    <Typography color="text.primary" gutterBottom variant="h3" sx={{ fontWeight: 'bold' }}>
                        {t('Home.organization.text')}
                    </Typography>
                    <Typography paragraph textAlign="center" color="text.secondary">
                        {t('Home.organization.description')}
                    </Typography>
                </Box>
                <Grid
                    container
                    spacing={3}
                    sx={{
                        justifyContent: {
                            xs: 'center',
                            lg: 'space-between'
                        }
                    }}
                >
                    {
                        teamInfoCollection.map(({ name, img, role, email, linkedin }) => (
                            <Grid
                                item
                                key={name}
                            >
                                <Card
                                    sx={{
                                        width: '275px'
                                    }}
                                >
                                    <Box sx={{ pt: '100%', position: 'relative' }}>
                                        <img
                                            style={{
                                                top: 0,
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                                position: 'absolute',
                                            }}
                                            src={img}>
                                        </img>
                                    </Box>
                                    <CardContent>
                                        <Typography textAlign="center" variant="subtitle1" noWrap>
                                            {name}
                                        </Typography>
                                        <Typography color="primary.main" textAlign="center" variant="subtitle2" noWrap>

                                            <Divider>{t(role)}</Divider>
                                        </Typography>
                                    </CardContent>

                                    <CardActions
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'end'
                                        }}
                                    >

                                        <IconButton
                                            aria-label="email"
                                            component="a"
                                            href={`mailto:${email}`}
                                        >
                                            <EmailIcon />
                                        </IconButton>
                                        <IconButton
                                            aria-label="linkedin"
                                            component="a"
                                            href={linkedin}
                                        >
                                            <LinkedInIcon />
                                        </IconButton>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))
                    }
                </Grid>
            </Container>
        </Box >
    );
};



export default Team;
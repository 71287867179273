import { Box, styled, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React, {useState, useEffect} from "react";
import landing from "../../assets/img/landing.png";
import { motion, easeInOut } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import Cookies from "js-cookie";

const fadeIn = (direction, delay) => {
    return {
        hidden: {
            y: direction === 'up' ? 80 : direction === 'down' ? -80 : 0,
            opacity: 0,

            x: direction === 'left' ? 80 : direction === 'right' ? -80 : 0,
        },
        show: {
            y: 0,
            x: 0,
            opacity: 1,
            transition: {
                type: 'tween',
                duration: 1.2,
                delay: delay,
                ease: [0.25, 0.25, 0.25, 0.75],
            },
        },
    };
};

const Presentation = () => {

    const { t } = useTranslation();
    const savedLanguage = Cookies.get('language');
    useEffect(() => {
        if (savedLanguage) {
        i18n.changeLanguage(savedLanguage);
        }
    }, []);

    return (
        <Box
            component="section"
            sx={{
                backgroundColor: 'background.default',
                padding: 2,
                height: {
                    xs: '100%',
                    lg: '100vh'
                },
            }}
        >
            <Container
                sx={{
                    paddingX: '10px',
                    height: '100%'
                }}
            >
                <Box
                    component={motion.div}
                    variants={fadeIn('up', 0.4)}
                    initial='hidden'
                    whileInView={'show'}
                    viewport={{ once: false, amount: 0.6 }}
                    sx={{
                        display: 'flex',
                        gap: {
                            xs: 0,
                            lg: 10
                        },
                        flexDirection: {
                            xs: 'column',
                            lg: 'row'
                        },
                        justifyContent: {
                            lg: 'flex-start'
                        },
                        alignItems: 'center',
                        height: '100%'
                    }}
                >
                    <Box
                        sx={{
                            textAlign: 'center',
                            maxWidth: {
                                lg: '36rem'
                            }
                        }}
                    >
                        <Typography color="text.primary" variant="h1" sx={{ fontWeight: 'bold', marginY: 2 }}>
                            <span dangerouslySetInnerHTML={{ __html: t("Home.description") }} />
                        </Typography>
                        <Typography paragraph sx={{ color: "text.secondary" }}>
                            {t("Home.description2")}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            width: {
                                xs: '80%',
                                sm: '70%',
                                md: '50%',
                                lg: '80%'
                            }
                        }}
                    >
                        <img
                            style={{
                                width: 'auto',
                                height: 'auto',
                                maxWidth: '100%',
                                maxHeight: '100%',
                                padding: 0,
                                margin: 0,
                            }}
                            src={landing}
                        >
                        </img>
                    </Box>
                </Box>
            </Container>
        </Box>

    );
};

export default Presentation;
import { Box, Button, Card, CircularProgress, Grid, IconButton, InputLabel, Stack, Table, TableBody, TableCell, TableRow, TextField, Typography, Zoom } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { UploadCsvFile } from "./UploadCsvFile";
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from 'react-router-dom';
import {useEffect, useRef, useState} from "react";
import { useSelector } from 'react-redux';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { uploadProducts } from "../../../../lib/api/suppliers";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import i18n from "i18next";

export const ProductForm = () => {

    const { t } = useTranslation();
    const savedLanguage = Cookies.get('language');
    useEffect(() => {
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, []);
    
    const navigate = useNavigate();
    const user = useSelector(state => state.user);
    const supplierId = user?.userID;

    const [csvData, setCsvData] = useState([]);

    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        defaultValues: {

        }
    });

    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: ({ file }) => {
            let formData = new FormData();
            formData.append('file', file[0]);
            return uploadProducts(formData);
        },
        onMutate: (values) => {
            //Returned values ​​are passed to context
        },
        onSuccess: (result, variables, context) => {
            queryClient.invalidateQueries(['products']);
            setTimeout(() => {
                navigate(`/app/suppliers/${supplierId}/products`);
            }, 1000);
        },
        onError: (result, variables, context) => {
            //show error
        },
    })


    return (
        <Stack
            sx={{
                gap: 3,
            }}
            component="form"
            onSubmit={handleSubmit(mutation.mutate)}
        >
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    xs={12}
                >

                    <Card
                        sx={{
                            padding: 2
                        }}
                    >
                        <UploadCsvFile
                            setCsvData={setCsvData}
                            inputProps={{
                                accept: '.csv'
                            }}
                            buttonProps={{
                                disabled: mutation.isLoading
                            }}
                            name="file"
                            label={t('create_products.new.subtitle')}
                            errors={errors}
                            register={register}
                        />
                    </Card>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={2}
                justifyContent="flex-end"
            >

                <Grid
                    item
                    xs={12}
                >
                    <Typography variant="h6" gutterBottom>
                        {t('create_products.preview.title')}
                    </Typography>

                    {
                        csvData.length === 0 &&
                        <Card
                            sx={{
                                padding: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: "center",
                                alignItems: 'center'
                            }}
                        >
                            <Typography textAlign="center" variant="h6" gutterBottom color="text.secondary">
                                {t('create_products.preview.empty')}
                            </Typography>
                            <NoteAddIcon
                                fontSize="large"
                                sx={{
                                    color: "text.secondary"
                                }}
                            />
                        </Card>
                    }

                    {
                        csvData.length !== 0 &&
                        <Zoom in={csvData.length !== 0}>
                            <Card>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        {
                                            csvData.slice(0, 10).map((row, idx) => (
                                                <TableRow
                                                    key={idx}
                                                >
                                                    {
                                                        row.map((column, idx) => (
                                                            <TableCell key={`${column}${idx}`}>
                                                                {column}
                                                            </TableCell>
                                                        ))
                                                    }
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </Card>
                        </Zoom>
                    }

                </Grid>
                <Grid
                    item
                    xs={6}
                >
                    <Box sx={{ position: 'relative' }}>
                        <Button
                            variant="contained"
                            startIcon={<SendIcon />}
                            fullWidth
                            type="submit"
                            disabled={mutation.isLoading || (csvData.length === 0)}
                        >
                            {t('create_products.create')}
                        </Button>
                        {mutation.isLoading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: 'primary.main',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Stack>
    )
}

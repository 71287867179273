import { LinearProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { CompanyPricingsPage } from './components/companies/CompanyPricingsPage';
import { SupplierPricingsPage } from './components/suppliers/SupplierPricingsPage';


export const PricingsPage = () => {

    const user = useSelector(state => state.user);

    if (!user) return <LinearProgress />
    
    if (user.userType === 'company'){
        return <CompanyPricingsPage />
    }

    if (user.userType === 'supplier'){
        return <SupplierPricingsPage />
    }
}

import Presentation from "../../components/landing/Presentation";
import Sponsors from "../../components/landing/Sponsors";
import Purpose from "../../components/landing/Purpose";
import React from "react";
import Team from "../../components/landing/Team";
import Grid from "@mui/material/Grid";
import { Actors } from "../../components/landing/Actors";
import { Awards } from "../../components/landing/Awards";
import { useTranslation } from 'react-i18next';

function HomePage() {
    return (
        <Grid>
            <Presentation />
            <Purpose />
            <Actors />
            <Awards />
            <Team/>
        </Grid>
    );
}

export default HomePage;
import React from 'react';
import { useSelector } from 'react-redux';
import { LinearProgress } from '@mui/material';
import { SupplierProducts } from '../components/SupplierProducts';

export const SupplierProductsPage = () => {
  const user = useSelector(state => state.user);

  if (!user) return <LinearProgress />

  if (user.userType === 'supplier'){
     return < SupplierProducts/>
  }

  return < LinearProgress  />
};
import React, { useEffect, useState } from 'react'
import { Card, CardContent, CardHeader, Button, Grid, ListItem, ListItemText, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { format } from 'date-fns';
import dataProvider from "../../../../lib/DataProvider";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import i18n from "i18next";

const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

const stringAvatar = (name) => {
    const nameParts = name.split(' ');


    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: nameParts.length >= 2 ? `${nameParts[0][0]}${nameParts[1][0]}` : `${nameParts[0][0]}`,
    };
}

const Message = (props) => {
    let name = ""
    if (props.type === "sender") {
        name = props.sender.name;
    } else {
        name = props.recipient.name;
    }

    const text = atob(props.text);

    return (
        <ListItem alignItems="flex-start">
            <ListItemAvatar>
                <Avatar {...stringAvatar(name)} />
            </ListItemAvatar>
            <ListItemText
                primary={
                    <Typography
                        textAlign="justify"
                    >
                        {text}
                    </Typography>
                }
                secondary={
                    <Typography
                        variant="body2"
                        color="#888"
                        align="right"
                    >
                        {props.datetime}
                    </Typography>
                }
            />
        </ListItem>
    );
};

export default function Chat(props) {

    const { t } = useTranslation();
    const savedLanguage = Cookies.get('language');
    useEffect(() => {
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, []);

    const [newItem, setNewItem] = useState('');
    const [list, setList] = useState(props.conversation);
    const [currentDate, setCurrentDate] = useState('');
    useEffect(() => {
        const getCurrentDate = () => {
            const date = new Date();
            const offset = -3;
            date.setUTCHours(date.getUTCHours() + offset)
            const formattedDate = date.toISOString().slice(0, 16);
            setCurrentDate(formattedDate);
        };

        getCurrentDate();
    }, []);
    const handleChange = (event) => {
        setNewItem(event.target.value);
    };

    const handleAdd = () => {
        if (newItem.trim() !== '') {
            setList([...list,
                {
                    type: "sender",
                    message: btoa(newItem),
                    timestamp: currentDate
                }
            ]);
            setNewItem('');

            const message ={
                user: props.sender.id,
                message: newItem
            }

            const body = {
                idPricing: props.idPricing,
                users: [props.sender.id,props.recipient.id],
                message: {
                    user: message.user,
                    message:btoa(message.message)
                }
            }

            dataProvider.post(`/chats/conversations`, body).then((response) => {
                console.log(response.data.message);
            }).catch((error) => {
                console.log(error);
            });

        }
    };

    return (
        <Card>
            <CardHeader
                avatar={
                    <Avatar
                        {...stringAvatar(props.recipient.name)}
                        aria-label="recipe"
                    />
                }
                title={
                    <div style={{ fontWeight: 'bold' }}>
                        {props.recipient.name}
                    </div>
                }
                subheader={
                    <div>
                        {props.recipient.company}
                        <br />
                        {props.recipient.type}
                    </div>
                }
            />
            <Divider />
            <CardContent>
                <List sx={{ width: '100%', bgcolor: 'background.paper' }} style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    {list.map((item, index) =>
                        <>
                            {index === 0 ? <></> : <Divider />}
                            <Message
                                key={index}
                                type={item.type}
                                text={item.message}
                                datetime={
                                    format(new Date(item.timestamp), 'dd/MM/yyyy HH:mm')
                                }
                                recipient = {props.recipient}
                                sender = {props.sender}
                            />
                        </>
                    )}
                </List>
                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12} md={10}>
                        <TextField
                            fullWidth
                            label={t('pricing.chat.text')}
                            multiline
                            rows={2}
                            value={newItem}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Button
                            onClick={handleAdd}
                            style={ {width: '100%', height: '100%' }}
                            variant="contained"
                        >
                            {t('pricing.chat.subtext')}
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
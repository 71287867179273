import {
	createBrowserRouter, Route, createRoutesFromElements,
} from "react-router-dom";
import React from "react";
import { HomeLayout } from "./lib/HomeLayout";
import SuspendAccount from "./pages/SuspendAccount";
import {
	LogIn,
	SignUp,
	Profile,
	Logout,
} from "./pages";
import { ProtectedLayout } from "./lib/ProtectedLayout";
import { IgnoreLayout } from "./lib/IgnoreLayout";
import NotFound from "./pages/NotFound";
import HomePage from "./pages/home/Page";
import { CreatePricingPage } from "./pages/app/pricings/create/Page";
import { PricingsPage } from "./pages/app/pricings/Page";
import { PricingPage } from "./pages/app/pricings/pricingId/Page";
import { ProductsPage } from "./pages/app/products/Page";
import { SupplierProductsPage } from './pages/app/products/supplier-products/SupplierProductsPage';
import { CreateProductsPage } from "./pages/app/products/create/CreateProductsPage";

const buttonCreateStyle = { color: "white", background: "#0FC1A1", "&:hover": { backgroundColor: "#0A8770" } }
const buttonDeleteStyle = { color: "white", background: "#b6000f", "&:hover": { backgroundColor: "#d21d24" } }
const buttonDoNothingStyle = { color: "white", background: "#4753b5", "&:hover": { backgroundColor: "#6775c9" } }
const buttonSubmitFile = { color: "white", background: "#7b1fa2", "&:hover": { backgroundColor: "#ba68c8" } }
const buttonFinishedStyle = { color: "white", background: "#106EE8", "&:hover": { backgroundColor: "#008fff" } }

export const router = createBrowserRouter(createRoutesFromElements(
	<>
		<Route element={<HomeLayout />}>
			<Route path="/" element={<HomePage />} />
			<Route path="/signup" element={<SignUp />} />
			<Route path="/login" element={<LogIn />} />
      <Route path="/suspended_account" element={<SuspendAccount />} />
		</Route>
		<Route path="/app" element={<ProtectedLayout />}>
			<Route path="" element={<HomePage />} />
			<Route path="profile/" element={<Profile />} />
			<Route path="pricings/" element={<PricingsPage />} />
			<Route path="pricings/:pricingId" element={<PricingPage />} />
			<Route path="products/:pricingId" element={<ProductsPage />} />
			<Route path="products/create" element={<CreateProductsPage />} />
			<Route path="pricings/create" element={<CreatePricingPage />} />
			<Route path="suppliers/:id/products" element={<SupplierProductsPage />} />
			<Route path="logout" element={<Logout />} />
		</Route>
		<Route element={<IgnoreLayout />}>
			<Route path="*" element={<NotFound />} />
		</Route>
	</>
));
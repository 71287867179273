import { Navigate, Outlet } from "react-router-dom";
import {isAuthenticated} from "./Auth";
import Header from "../components/Header";
import Footer from "../components/Footer";
import GlobalSnackbar from "../components/GlobalSnackbar";
import Grid from "@mui/material/Grid";

export const HomeLayout = () => {
    if (isAuthenticated()) {
        return <Navigate to="/app" />;
    }

    return (
        <Grid sx={{ display: 'flex', flexDirection: 'column', minHeight: "100vh"}}>
            <Header />
            <Outlet />
            <Footer />
            <GlobalSnackbar />
        </Grid>
    )
};
import styled from "@emotion/styled";
import { Box, FormControl, FormControlLabel, FormHelperText, InputLabel, Radio, RadioGroup, Typography, useRadioGroup } from "@mui/material";

export const RadioSelect = ({ register, errors, selectsContainerStyles, label = 'Label', name = 'inputName', options = [
    {
        value: 'title',
        title: 'Título',
        subtitle: 'Subtítulo'
    }
] }) => {

    return (
        <Box>
            <InputLabel
                id={name}
                sx={{
                    marginBottom: 1
                }}
            >
                {label}
            </InputLabel>
            <FormControl
                fullWidth
                error
            >
                <RadioGroup
                    aria-labelledby={name}
                    defaultValue=""
                    name={name}
                >
                    <Box sx={selectsContainerStyles}>
                        {
                            options.map(({ value, title, subtitle }) => (
                                <MyFormControlLabel
                                    sx={{ flexGrow: 1 }}
                                    key={value}
                                    value={value}
                                    control={
                                        <Radio
                                            {...register(`${name}`,
                                                { required: 'Este campo es requerido' }
                                            )}
                                            value={value}
                                        />}
                                    label={
                                        <Box
                                            sx={{ marginY: 1 }}
                                        >
                                            <Typography >{title}</Typography>
                                            {
                                                !!subtitle && <Typography component="p" color="text.secondary">{subtitle}</Typography>
                                            }

                                        </Box>
                                    }
                                />
                            ))
                        }
                    </Box>
                </RadioGroup>
                <FormHelperText>{errors[name]?.message}</FormHelperText>
            </FormControl>
        </Box>
    )
}

const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(
    ({ theme, checked }) => ({
        backgroundColor: checked && 'RGBA(15, 193, 161, 0.1)',
        border: '1px solid',
        borderColor: checked ? 'RGBA(15, 193, 161)' : 'RGBA(15, 193, 161, 0.4)',
        boxShadow: 1,
        margin: 0,
        marginBottom: 8,
        borderRadius: '5px',
        width: '100%'
    }),
);

function MyFormControlLabel(props) {

    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
        checked = radioGroup.value === props.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
}
import { Card, CardMedia, CardContent, CardHeader, Typography } from "@mui/material";
import React from "react";

/**
 * Generates a card element with the according fields.
 * Hopefully useful for displaying dynamic content.
 * @param {*} title 
 * @param {*} subtitle 
 * @param {*} description 
 * @param {*} image 
 * @returns 
 */
export function generateProjectCard(title, subtitle, description, image=null) {

    if (image) {
        return(
        <ProjectCardDisplay title={title} subtitle={subtitle} image={image}>
            {description}
        </ProjectCardDisplay>
        );
    }
    else {
        return (
            <ProjectCardDisplay title={title} subtitle={subtitle}>
                {description}
            </ProjectCardDisplay>
        );
    }
}


/**
 * Card component to display projects.
 * Description goes on the body of the tag.
 * We should add some sort of way to make these cards read project data dynamically.
 * @param {*} props
 *  props.title: title of the project
 *  props.subtitle: subtitle of the project
 *  props.image: image of the project (not implemented yet)
 * @returns {JSX.Element} 
 */
export function ProjectCardDisplay(props) {
    return (
        <Card>
            {props.image ? <CardMedia component="img" height={120} image={props.image} alt="Project Image"/> : null}
            <CardHeader title={props.title} subheader={props.subtitle}></CardHeader>
            <CardContent>
                <Typography variant="body2" color="text.secondary">
                    {props.children}
                </Typography>
            </CardContent>
        </Card>
    );

}


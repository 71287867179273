import { Button, Card, Checkbox, CircularProgress, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Slider, TextField, Tooltip, Typography, useRadioGroup } from '@mui/material';
import { Box, Container, Stack } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SendIcon from '@mui/icons-material/Send';
import { useRef } from 'react';
import { createPricing } from '../../../../lib/api/pricings';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { green } from '@mui/material/colors';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { Link } from 'react-router-dom';
import { RadioSelect } from '../../../../components/forms/RadioSelect';
import { useSelector } from 'react-redux';
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import i18n from "i18next";

const currencyOptions = [
    {
        value: "CLP",
        label: "CLP",
    },
    {
        value: "USD",
        label: "USD",
    },
    {
        value: "UF",
        label: "UF",
    },
];

export const CreatePricingPage = () => {

    const { t } = useTranslation();
    const savedLanguage = Cookies.get('language');
    useEffect(() => {
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, []);

    const constructionTypes = [
        {
            "nombre": t('create_pricings.type.residential.title'),
            "descripcion": t('create_pricings.type.residential.subtitle')
        },
        {
            "nombre": t('create_pricings.type.commercial.title'),
            "descripcion": t('create_pricings.type.commercial.subtitle')
        },
        {
            "nombre": t('create_pricings.type.industrial.title'),
            "descripcion": t('create_pricings.type.industrial.subtitle')
        },
        {
            "nombre": t('create_pricings.type.institutional.title'),
            "descripcion": t('create_pricings.type.institutional.subtitle')
        },
        {
            "nombre": t('create_pricings.type.infrastructure.title'),
            "descripcion": t('create_pricings.type.infrastructure.subtitle')
        }
    ]

    const user = useSelector(state => state.user);

    const [fundingCheckboxChecked, setfundingCheckboxChecked] = useState(false);
    const [successResponse, setSuccessResponse] = useState({});
    const [file, setFile] = useState();
    const fundingInputRef = useRef();

    useEffect(() => {
        if (fundingCheckboxChecked) {
            fundingInputRef.current.focus();
        }
    }, [fundingCheckboxChecked, fundingInputRef]);

    const handleFileChange = (e) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };

    const handleChangeFundingCheckbox = (event) => {
        setfundingCheckboxChecked(event.target.checked);
    };

    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        defaultValues: {
            fundingPercentage: 0
        }
    });

    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: ({ file, ...data }) => {
            let formData = new FormData();
            formData.append('file', file[0]);
            for (const [key, value] of Object.entries(data)) {
                formData.append(`${key}`, value);
            }
            return createPricing(formData);
        },
        onMutate: (values) => {
            //Returned values ​​are passed to context
        },
        onSuccess: (result, variables, context) => {
            setSuccessResponse(result);
            queryClient.invalidateQueries(['pricings'])
        },
        onError: (result, variables, context) => {
            //show error
        },
    })

    if (user.userType === 'supplier')
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
                <Card sx={{ padding: 5 }}>
                    <Typography variant="h3">Debes ser una Empresa para crear una cotización</Typography>
                </Card>
            </Box>
        )

    if (mutation.isSuccess) {
        return (
            <Container
                sx={{
                    padding: '1rem',
                    display: 'flex',
                    alignItems: 'center'
                }}
                maxWidth="md"
            >
                <Stack
                    component={Card}
                    width='100%'
                    sx={{
                        marginY: {
                            xs: '30px',
                            md: '60px'
                        }
                    }}
                >
                    <Grid
                        container
                        sx={{
                            gap: 3
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                            sx={{
                                backgroundColor: green[500],
                                textAlign: "center"
                            }}
                        >
                            <CheckCircleOutlineOutlinedIcon
                                sx={{
                                    fontSize: 80,
                                    color: "white",
                                    marginY: '1rem'
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                        >
                            <Typography variant='h3' textAlign="center" sx={{ margin: '1rem' }}>
                                ¡Cotización
                                <Box component="span" sx={{ color: 'primary.main' }}> {successResponse?.data?.newPricing?.title ?? ' '} </Box>
                                creada con exito!
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            padding={2}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <Button
                                variant="contained"
                                startIcon={<ArrowBackOutlinedIcon />}
                                size="large"
                                component={Link}
                                to={'/app/pricings'}
                            >
                                Regresar a mis cotizaciones.
                            </Button>
                        </Grid>
                    </Grid>
                </Stack>
            </Container>
        )
    }

    return (
        <Container
            sx={{
                padding: '1rem',
                height: '100%'
            }}
            maxWidth="md"
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginY: 3
                }}
            >
                <Typography variant="h4" gutterBottom>
                    {t('create_pricings.title')}
                </Typography>
            </Box>
            <Card>
                <Stack
                    padding={2}
                    sx={{
                        gap: 3
                    }}
                    component="form"
                    onSubmit={handleSubmit(mutation.mutate)}
                >
                    <Grid
                        container
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <InputLabel
                                id="title"
                                sx={{
                                    marginBottom: 1
                                }}
                            >
                                {t('create_pricings.name')}
                            </InputLabel>
                            <TextField
                                id='title'
                                fullWidth
                                placeholder={t('create_pricings.placeholder')}
                                {...register("title", {
                                    required: t('login.required')

                                })}
                                error={!!errors.title}
                                helperText={errors.title?.message}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={8}
                        >
                            <InputLabel
                                id="presupuesto"
                                sx={{
                                    marginBottom: 1
                                }}
                            >
                                {t('create_pricings.budget.title')}
                            </InputLabel>
                            <TextField
                                label={t('create_pricings.budget.subtitle')}
                                type="number"
                                fullWidth
                                placeholder="1600000"
                                id='presupuesto'
                                {...register('budgetPrice', {
                                    required: t('login.required'),
                                    valueAsNumber: true,
                                })}
                                error={!!errors.budgetPrice}
                                helperText={errors.budgetPrice?.message}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={4}
                        >
                            <InputLabel
                                id="currency-label"
                                sx={{
                                    marginBottom: 1
                                }}
                            >
                                {t('create_pricings.currency')}
                            </InputLabel>
                            <FormControl fullWidth>
                                <Select
                                    labelId="currency-label"
                                    {...register("currency")}
                                    defaultValue="CLP"
                                >
                                    {
                                        currencyOptions.map(({ value, label }) => (
                                            <MenuItem key={value} value={value}>{label}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                            lg={12}
                        >
                            <FormControl>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={fundingCheckboxChecked}
                                            onChange={handleChangeFundingCheckbox}
                                        />
                                    }
                                    label={t('create_pricings.funding_text')}
                                    slotProps={{
                                        typography: {
                                            color: 'text.secondary'
                                        }
                                    }}
                                    component="span"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                            lg={6}
                        >
                            <InputLabel
                                id="fundingPercentage"
                                sx={{
                                    marginBottom: 1
                                }}
                            >
                                {t('create_pricings.funding')}
                            </InputLabel>
                            <TextField
                                inputProps={{
                                    min: 0,
                                    max: 100
                                }}
                                {...register('fundingPercentage', {
                                    required: t('login.required'),
                                    valueAsNumber: true,
                                    min: 0,
                                    max: 100
                                })}
                                id='fundingPercentage'
                                inputRef={fundingInputRef}
                                type="number"
                                fullWidth
                                disabled={!fundingCheckboxChecked}
                                error={fundingCheckboxChecked && !!errors.fundingPercentage}
                                helperText={fundingCheckboxChecked && errors.fundingPercentage?.message}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            lg={6}
                        >
                            <InputLabel
                                id="file-input"
                                sx={{
                                    marginBottom: 1
                                }}
                            >
                                {t('create_pricings.ifc.title')}
                            </InputLabel>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <TextField
                                    id="outlined-read-only-input"
                                    value={!!file ? file.name : t('create_pricings.ifc.text')}
                                    InputProps={{
                                        readOnly: true,
                                        style: {
                                            borderRadius: '4px 0px 0px 4px',
                                        }
                                    }}
                                    sx={{
                                        flexGrow: 3,
                                    }}
                                    error={!!errors.file}
                                    helperText={errors.file?.message}
                                />
                                <Box
                                    sx={{
                                        flexGrow: 1
                                    }}
                                >
                                    <label htmlFor="file-upload"
                                        style={{
                                            width: '100%'
                                        }}
                                    >
                                        <input
                                            style={{ display: "none" }}
                                            id="file-upload"
                                            type="file"
                                            accept=".ifc"
                                            {...register('file', {
                                                required: t('create_pricings.ifc.required'),
                                                onChange: handleFileChange
                                            })}
                                        />
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            color='secondary'
                                            sx={{
                                                height: 56,
                                                borderRadius: '0px 4px 4px 0px',
                                            }}
                                            startIcon={<FileUploadIcon />}
                                            component="span"
                                            disabled={mutation.isLoading}
                                        >
                                            {t('create_pricings.ifc.upload')}
                                        </Button>
                                    </label>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <RadioSelect
                                errors={errors}
                                register={register}
                                name="constructionType"
                                label={t('pricing.description.type')}
                                options={constructionTypes.map(({ nombre, descripcion }) => ({ value: nombre, title: nombre, subtitle: descripcion }))}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        justifyContent="flex-end"
                    >
                        <Grid
                            item
                            lg={6}
                            xs={12}
                        >
                            <Box sx={{ m: 1, position: 'relative' }}>
                                <Button
                                    variant="contained"
                                    startIcon={<SendIcon />}
                                    fullWidth
                                    type="submit"
                                    disabled={mutation.isLoading}
                                >
                                    {t('create_pricings.enter')}
                                </Button>
                                {mutation.isLoading && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: 'primary.main',
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Stack>
            </Card>
        </Container >
    )
}

const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(
    ({ theme, checked }) => ({
        backgroundColor: checked && 'RGBA(15, 193, 161, 0.1)',
        border: '1px solid',
        borderColor: checked ? 'RGBA(15, 193, 161)' : 'RGBA(15, 193, 161, 0.4)',
        boxShadow: 1,
        margin: 0,
        marginBottom: 8,
        borderRadius: '5px',
    }),
);

function MyFormControlLabel(props) {

    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
        checked = radioGroup.value === props.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
}
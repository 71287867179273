import { Alert, AlertTitle, Box, Button, Container, Grid, LinearProgress, Typography} from '@mui/material';
import { getProducts } from '../../../../lib/api/suppliers';
import { DataGrid, esES } from '@mui/x-data-grid'
import { useQuery } from '@tanstack/react-query'
import { Link as RouterLink } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AddIcon from '@mui/icons-material/Add';
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import React, {useEffect} from "react";
import i18n from "i18next";


export const SupplierProducts = () => {

    const { t } = useTranslation();
    const savedLanguage = Cookies.get('language');
    useEffect(() => {
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, []);

    const { data, isLoading, isSuccess } = useQuery({ queryKey: ['products'],  queryFn: () => getProducts()});

    const products = data && data.data && data.data.data ? data.data.data : [];
    const rows = products.flatMap((item) =>  
        item.products.map((product) => ({
            id: product.id,
            name: product.name,
        }))
    );

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'name', headerName: t('products_supplier.name'), flex: 1 },
    ];

    if (!data && isLoading) return <LinearProgress />

    return (
        <Container maxWidth="lg" sx={{ marginY: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginY: 3 }} >
                <Typography variant="h4">
                    {t('products_supplier.title')}
                </Typography>
                <Button component={RouterLink} to={`/app/pricings`} variant="outlined" startIcon={<ArrowBackIosIcon />}>{t('products_supplier.back')}</Button>
            </Box>
            <Grid item xs={12} style={{ marginBottom: '20px' }}>
                    <Alert severity="info">
                        <AlertTitle>{t('products_supplier.information.title')}</AlertTitle>
                        <span dangerouslySetInnerHTML={{ __html: t('products_supplier.information.subtitle') }} />
                    </Alert>
                    <Box sx={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button component={RouterLink} to={`/app/products/create`} variant="contained" startIcon={<AddIcon />} style={{ height: '40px' }} >{t('products_supplier.new')}</Button>
                    </Box>
            </Grid>
            <Grid item xs={12} sx={{ height: 631, width: '100%' }}>
                {
                isSuccess && data &&
                    <DataGrid
                        loading={isLoading}
                        localeText={{
                            ...esES.components.MuiDataGrid.defaultProps.localeText,
                            noRowsLabel: t('products_supplier.label')
                        }}
                        rows={rows}
                        columns={columns}
                        pageSize={100}
                        rowsPerPageOptions={[100]}
                    />
                }
            </Grid>
        </Container>
    )

}
import { Box, Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, LinearProgress, Stack, Typography } from "@mui/material";
import { updatePricing } from "../../../../../lib/api/pricings";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useModals } from "../../../../../features/ui/ModalContext";
import { useEffect, useState } from "react";

export const ConfirmSuppliersForm = ({ pricingId, selectedSuppliersIds = [], suppliersData = [] }) => {

    const queryClient = useQueryClient();
    const { handleClose } = useModals();
    const [data, setData] = useState([]);
    const [selectedSuppliersData, setSelectedSuppliersData] = useState([]);

    useEffect(() => {
        let newData = []
        suppliersData.forEach((supplier) => {
            if (selectedSuppliersIds.includes(supplier.user._id)) {
                newData.push(
                    {
                        user: supplier.user._id,
                        status: {
                            ...supplier.status,
                            selected: true
                        }
                    }
                )
            } 
        });
        setData(newData);
        const newSelectedSuppliersData = suppliersData.filter((supplier) => selectedSuppliersIds.includes(supplier.user._id));
        setSelectedSuppliersData(newSelectedSuppliersData);
    }, [selectedSuppliersIds, suppliersData])

    const mutation = useMutation({
        mutationFn: () => {
            return updatePricing(pricingId, { suppliers: data });
        },
        onMutate: (values) => {
            //Returned values ​​are passed to context
        },
        onSuccess: (result, variables, context) => {
            queryClient.invalidateQueries(['pricings']);
            handleClose();
        },
        onError: (result, variables, context) => {
            //show error
        },
    })

    return (
        <Box>
            <DialogTitle>
                Confirmación
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {
                        selectedSuppliersData.length === 1 ? '¿Estas seguro de confirmar el siguiente proveedor?' : '¿Estas seguro de confirmar los siguientes proveedores?'
                    }
                </DialogContentText>
                <Stack sx={{ marginTop: 1 }} alignItems="center">
                    {
                        selectedSuppliersData.map(supplier => (
                            <Box key={supplier.user.corporation.name} component="span" fontWeight='fontWeightMedium'>{supplier.user.corporation.name}</Box>
                        ))
                    }
                </Stack>
                {
                    mutation.isLoading && <Box sx={{ marginY: 2 }}><LinearProgress /></Box>
                }
            </DialogContent>
            <DialogActions sx={{
                gap: 2
            }}>
                <Button onClick={handleClose} color="info">
                    Volver
                </Button>
                <Button
                    onClick={mutation.mutate}
                    color="success"
                    disabled={mutation.isLoading}
                >
                    Confirmar proveedores
                </Button>
            </DialogActions>
        </Box>
    )
}

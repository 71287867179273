import dataProvider from "../DataProvider";

export const uploadProducts = (formData) => {
    return dataProvider.post('/suppliers/upload-products', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

export const getProducts = (userId = '') => {
    const endpoint = userId ? `/products/${userId}` : '/products';
    return dataProvider.get(endpoint);
}
import { createSlice } from '@reduxjs/toolkit'

const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState: {
        open: false,
        message: "",
        severity: "success"
    },
    reducers: {
        openSnackbar: (state, action) => {
            state.open = true;
            state.message = action.payload.message;
            state.severity = action.payload.severity;
        },
        closeSnackbar: (state) => {
            state.open = false;
        }
    }
})

export const {openSnackbar, closeSnackbar} = snackbarSlice.actions;

export default snackbarSlice.reducer;
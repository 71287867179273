import {Card, Container, Grid, Stack} from "@mui/material";
import {red} from "@mui/material/colors";
import ClearIcon from "@mui/icons-material/Clear";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import {useEffect} from "react";
import i18n from "i18next";

const SuspendAccount = () => {

    const { t } = useTranslation();
    const savedLanguage = Cookies.get('language');
    useEffect(() => {
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, []);

    return(
        <Container
            sx={{
                padding: '1rem',
                display: 'flex',
                alignItems: 'center'
            }}
            maxWidth="md"
        >
            <Stack
                component={Card}
                width='100%'
                sx={{
                    marginY: {
                        xs: '30px',
                        md: '60px'
                    }
                }}
            >
                <Grid
                    container
                    sx={{
                        gap: 3
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        sx={{
                            backgroundColor: red[500],
                            textAlign: "center"
                        }}
                    >
                        <ClearIcon
                            sx={{
                                fontSize: 80,
                                color: "white",
                                marginY: '1rem'
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <Typography variant='h3' textAlign="center" sx={{ margin: '1rem' }}>
                            {t('login.suspended')}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        padding={2}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                    </Grid>
                </Grid>
            </Stack>
        </Container>
    )
}

export default SuspendAccount
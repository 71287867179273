import { LinearProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { CompanyPricingsPage } from '../components/companies/CompanyPricingsPage';
import { CompanyPricingPage } from '../components/companies/CompanyPricingPage';
import { SupplierPricingPage } from '../components/suppliers/SupplierPricingPage';


export const PricingPage = () => {

    const user = useSelector(state => state.user);


    if (!user) return <LinearProgress />
    
    if (user.userType === 'company'){
        return <CompanyPricingPage />
    }

    if (user.userType === 'supplier'){
        return <SupplierPricingPage />
    }
}

import { getPricings } from '../../../../lib/api/pricings';
import { DataGrid, esES } from '@mui/x-data-grid'
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query'
import { Link as RouterLink, useParams} from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Container, Grid, LinearProgress, Typography} from '@mui/material';
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import {useEffect} from "react";
import i18n from "i18next";


export const Products = () => {

    const { t } = useTranslation();
    const savedLanguage = Cookies.get('language');
    useEffect(() => {
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, []);

    const { pricingId } = useParams();

    const { data, isLoading, isSuccess } = useQuery(['pricings', pricingId], () => getPricings(pricingId));

    const user = useSelector(state => state.user);

    const buttonCreateStyle = {color: "black", background:"#0FC1A1", "&:hover": {backgroundColor: "#0A8770"}, marginTop: 2, marginBottom: 2};

    let idCounter = 1;
    const rows = Boolean(data?.data?.products) ? data?.data?.products.flatMap((product, index) => 
        product.Elements.map((element) => ({
            id: idCounter++,
            material: product.Material,
            elemento: element.Name,
            cantidad: element.Bases.length
        }))
    ) : [];

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'material', headerName: t('products_company.material'), flex: 1 },
        { field: 'elemento', headerName: t('products_company.elements'), flex: 1 },
        { field: 'cantidad', headerName: t('products_company.amount'), flex: 1 },
    ];

    if (!data && isLoading) return <LinearProgress />

    return (
        <Container
            maxWidth="lg"
            sx={{ marginY: 2 }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginY: 3
                }}
            >
                <Typography variant="h4">
                    {t('products_company.title')}
                </Typography>
                <Button component={RouterLink} to={`/app/pricings/${pricingId}`} variant="outlined" startIcon={<ArrowBackIosIcon />}>{t('products_company.back')}</Button>
            </Box>
            <Grid item xs={12} sx={{ height: 650, width: '100%' }}>
                {
                isSuccess && data &&
                    <DataGrid
                        loading={isLoading}
                        localeText={{
                            ...esES.components.MuiDataGrid.defaultProps.localeText,
                            noRowsLabel: t('products_company.label')
                        }}
                        rows={rows}
                        columns={columns}
                        pageSize={100}
                        rowsPerPageOptions={[100]}
                    />
                }
            </Grid>
        </Container>
    )
}
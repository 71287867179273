

import { useState } from "react";
import { ModalContext } from "./ModalContext"
import { Card, Dialog, Modal } from "@mui/material";

export const ModalProvider = ({ children }) => {

    const [nodeElement, setNodeElement] = useState(null);
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    const actions = {
        push: (node) => {
            setOpen(true);
            setNodeElement(node);
        },
        handleClose
    }

    return (
        <ModalContext.Provider
            value={actions}
        >
            <Dialog
                maxWidth="md"
                open={open}
                onClose={handleClose}
            >
                {nodeElement}
            </Dialog>
            {children}
        </ModalContext.Provider >
    )
}

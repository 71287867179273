import { isAuthenticated } from "./Auth";
import { Navigate, Outlet } from "react-router-dom";
import React from "react";
import Grid from "@mui/material/Grid";
import Header from "../components/Header";
import Footer from "../components/Footer";
import GlobalSnackbar from "../components/GlobalSnackbar";
import { ModalProvider } from "../features/ui/ModalProvider";

export const ProtectedLayout = () => {
    if (!isAuthenticated()) {
        return <Navigate to="/" />;
    }
    return (

        <Grid sx={{ display: 'flex', flexDirection: 'column', minHeight: "100vh" }}>
            <Header />
            <ModalProvider>
                <Outlet sx={{ position: "relative" }} />
            </ModalProvider>
            <Footer />
            <GlobalSnackbar />
        </Grid>

    )
}
import styled from "@emotion/styled";
import { Box, Card, CardActions, CardContent, CardMedia, Container, Divider, Grid, Typography } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { motion } from "framer-motion";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import award1 from "../../assets/img/award1.jpg";
import award2 from "../../assets/img/award2.jpg";
import award3 from "../../assets/img/award3.png";
import award4 from "../../assets/img/award4.png";
import award5 from "../../assets/img/award5.png";
import award6 from "../../assets/img/award6.jpg";
import award7 from "../../assets/img/award7.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from 'swiper/modules';
import useMediaQuery from '@mui/material/useMediaQuery';

import 'swiper/css';
import {useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import i18n from "i18next";


export const awards = [
    {
        id: "1",
        img: award1,
        name: "3IE UTFSM",
        description: "Home.Institutions.3IE.subtitle",
        url: "https://3ie.usm.cl/"
    },

    {
        id: "4",
        img: award4,
        name: "Chrysalis PUCV",
        description: "Home.Institutions.Chrysalis.subtitle",
        url: "https://www.pucv.cl/uuaa/dgvm/programas-vcm/incubadora-de-negocios-chrysalis"
    },

    {
        id: "3",
        img: award5,
        name: "Incuba UC",
        description: "Home.Institutions.IncubaUC.subtitle",
        url: "https://centrodeinnovacion.uc.cl/incuba-uc/"
    },

    {
        id: "5",
        img: award2,
        name: "Imagine Lab",
        description: "Home.Institutions.ImagineLab.subtitle",
        url: "https://grupo-imagine.com/"
    },

    {
        id: "2",
        img: award3,
        name: "UDD Ventures",
        description: "Home.Institutions.UDDVentures.subtitle",
        url: "https://uddventures.udd.cl/"
    },

    {
        id: "6",
        img: award6,
        name: "Brain Chile",
        description: "Home.Institutions.BrainChile.subtitle",
        url: "https://brainchile.cl/"
    },

    {
        id: "7",
        img: award7,
        name: "Corfo Chile",
        description: "Home.Institutions.Corfo.subtitle",
        url: "https://www.corfo.cl/sites/cpp/homecorfo"
    }
];

const fadeIn = (direction, delay) => {
    return {
        hidden: {
            y: direction === 'up' ? 80 : direction === 'down' ? -80 : 0,
            opacity: 0,

            x: direction === 'left' ? 80 : direction === 'right' ? -80 : 0,
        },
        show: {
            y: 0,
            x: 0,
            opacity: 1,
            transition: {
                type: 'tween',
                duration: 1.2,
                delay: delay,
                ease: [0.25, 0.25, 0.25, 0.75],
            },
        },
    };
};

export const Awards = () => {

    const swiperContainerRef = useRef(null);

    const { t } = useTranslation();
    const savedLanguage = Cookies.get('language');
    useEffect(() => {
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, []);

    return (
        <Box
            component="section" sx={{
                backgroundColor: "#FFF",
                display: "flex",
                flexDirection: 'row',
                alignItems: 'center',
                height: {
                    xs: '100%',
                    md: '100vh'
                }
            }}>
            <Container
                sx={{
                    padding: '15px'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: 2
                    }}
                >
                    <Typography color="text.primary" gutterBottom variant="h3" sx={{ fontWeight: 'bold' }}>
                        {t('Home.Institutions.text')}
                    </Typography>
                    <Typography color="text.secondary" textAlign="center" paragraph> {t('Home.Institutions.description')}</Typography>
                </Box>

                <Box
                    variants={fadeIn('up', 0.4)}
                    initial='hidden'
                    whileInView={'show'}
                    viewport={{ once: true, amount: 0.2 }}
                    component={motion.div}
                    sx={{
                        display: 'flex'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                        }}
                    >
                        <IconButton
                            aria-label="previous slide"
                            size={useMediaQuery((theme) => theme.breakpoints.down('md')) ? 'medium' : 'large'}
                            onClick={() => swiperContainerRef.current.swiper.slidePrev()}
                        >
                            <ArrowBackIosIcon
                                fontSize="inherit"
                            />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            minWidth: 0
                        }}
                    >
                        <Swiper
                            breakpoints={{
                                0: { slidesPerView: 1 },
                                600: { slidesPerView: 2, spaceBetween: 15 },
                                900: { slidesPerView: 2, spaceBetween: 32 },
                                1200: { slidesPerView: 3, spaceBetween: 32 },
                            }}
                            modules={[Autoplay]}
                            autoplay={{ delay: 2000, disableOnInteraction: false }}
                            loop
                            ref={swiperContainerRef}
                        >
                            <Box>
                                {

                                    awards.map((award, index) => (
                                        <SwiperSlide key={index}>
                                            <Card sx={{ height: 300, marginX: 2, marginY: 4 }}>
                                                <CardMedia
                                                    sx={{ height: 200, backgroundSize: 'contain', backgroundPosition: 'center'}}
                                                    image={award.img}
                                                    title={award.name}
                                                />
                                                <CardContent>
                                                    <Typography gutterBottom variant="h5" component="div">
                                                        {award.name}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        {t(award.description)}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </SwiperSlide>
                                    ))
                                }
                            </Box>
                        </Swiper>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                        }}
                    >
                        <IconButton
                            aria-label="previous slide"
                            size={useMediaQuery((theme) => theme.breakpoints.down('md')) ? 'medium' : 'large'}
                            onClick={() => swiperContainerRef.current.swiper.slideNext()}
                        >
                            <ArrowForwardIosIcon
                                fontSize="inherit"
                            />
                        </IconButton>
                    </Box>
                </Box>

            </Container>
        </Box>
    )
}
import * as React from 'react';
import { TextField, Button, Box, IconButton, Container, Card, Stack, Grid, InputLabel, Divider, Chip, InputAdornment } from '@mui/material';
import { Link, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Cookies from 'js-cookie'
import '../config';
import dataProvider from "../lib/DataProvider";
import { useDispatch } from 'react-redux';
import { setUser, setUserType, setUserEmail, setUserCorp } from '../features/user/userSlice';
import { openSnackbar } from '../features/ui/snackbarSlice';
import { useForm } from 'react-hook-form';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {green, red} from "@mui/material/colors";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ClearIcon from '@mui/icons-material/Clear';
import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import i18n from "i18next";

const LogIn = () => {

    const { t } = useTranslation();
    const savedLanguage = Cookies.get('language');
    useEffect(() => {
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, []);

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const dispatch = useDispatch();


    const navigate = useNavigate();

    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     if (!isFormValid()) {
    //         alert('Incorrect form');
    //     } else {
    //         // handle form submission here
    //         dataProvider.post('/auth/login', formValues)
    //             .then((response) => {
    //                 Cookies.set('session_token', response.data.token, { expires: 1 / 4 }); // Set cookie to expire in 6 hours
    //                 dispatch(setUser(response.data.uid));
    //                 dispatch(openSnackbar({ "message": 'Inicio de sesión exitoso', "severity": 'success' }));
    //                 navigate('/app/profile/' + response.data.uid);
    //             }).catch((error) => {
    //                 dispatch(openSnackbar({ "message": error.message, "severity": 'error' }));
    //             });
    //     }
    // };


    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = (data) => {

        dataProvider.post('/auth/login', data)
            .then((response) => {
                if (!response.data.user.enabled){
                    navigate('/suspended_account')
                    return
                }
                Cookies.set('session_token', response.data.token, { expires: 1 / 4 }); // Set cookie to expire in 6 hours
                dispatch(setUser(response.data.user.id));
                dispatch(setUserEmail(response.data.user.email));
                dispatch(setUserType(response.data.user.accountType));
                dispatch(setUserCorp(response.data.user.corporation));
                dispatch(openSnackbar({ "message": t('login.ok'), "severity": 'success' }));
                navigate('/app/pricings');
            }).catch((error) => {
                dispatch(openSnackbar({ "message": error.message, "severity": 'error' }));
            });
    }

    return (
        <Container
            sx={{
                padding: '1rem',
                height: 'calc(100vh - 68px)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}
            maxWidth="sm"
        >
            <Card
                elevation={2}
            >
                <Stack
                    padding={2}
                    sx={{
                        gap: 3
                    }}
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Typography variant="h4" color="primary.main" textAlign="center">
                        {t('login.title')}
                    </Typography>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <InputLabel
                                id="title"
                                sx={{
                                    marginBottom: 2
                                }}
                            >
                                {t('login.mail')}
                            </InputLabel>
                            <TextField
                                type="email"
                                id='email'
                                // label="Email"
                                fullWidth
                                placeholder={t('login.placeholder')}
                                {...register("email", {
                                    required: t('login.required'),
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: t('login.requiredmessage')
                                    }
                                })}
                                error={!!errors.email}
                                helperText={errors.email?.message}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                        >
                            <InputLabel
                                id="password"
                                sx={{
                                    marginBottom: 2
                                }}
                            >
                                {t('login.password')}
                            </InputLabel>
                            <TextField
                                type={showPassword ? 'text' : 'password'}
                                id='password'
                                // label="Contraseña"
                                fullWidth
                                {...register("password", {
                                    required:  t('login.required'),
                                })}
                                error={!!errors.password}
                                helperText={errors.password?.message}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                }}

                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                size="large"
                                type="submit"
                            >
                                {t('login.enter')}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Divider
                                sx={{
                                    color: "text.secondary",
                                    marginBottom: 2
                                }}
                            >
                                {t('login.text')}
                            </Divider>
                            <Typography paragraph textAlign="center" color="text.primary">
                                {t('login.text2')}
                                <Link to={'/signup'}>
                                    {t('login.text3')}
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </Stack>
            </Card>
        </Container>
    );
}
export default LogIn;
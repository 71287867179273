import Presentation from "../components/landing/Presentation";
import Sponsors from "../components/landing/Sponsors";
import Purpose from "../components/landing/Purpose";
import React, {useEffect} from "react";
import Awards from "../components/landing/Awards";
import Team from "../components/landing/Team";
import Grid from "@mui/material/Grid";
import { Typography, Container, Button } from "@mui/material";

import { useNavigate } from "react-router-dom";
import errorImage from '../assets/img/error404.jpg'
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import i18n from "i18next";


function NotFound() {

    const { t } = useTranslation();
    const savedLanguage = Cookies.get('language');
    useEffect(() => {
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, []);

    let navigate = useNavigate();

    let handleClick = () => {
        navigate(-1);
    }

    return (
        <Container>
            <Typography variant="h1" component="div" style={{ textAlign: "center" }}>
                Oops!
            </Typography>
            <Grid container sx={{paddingTop: 2}}>
                <Grid item sm={12} md={6} justifyContent="center" display={"flex"}>
                    <img src={errorImage} alt="Error 404" style={{ width: "90%", minWidth:256 }}/>
                </Grid>
                <Grid item sm={12} md={6} style={{alignItems: "center", display:"flex"}} justifyContent={"center"}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h6" component="div" style={{color: "#888888"}}>
                                {t('not_found.text')}
                            </Typography>
                        </Grid>
                        <Grid item sm={12} md={3}>
                            <Button variant="contained" onClick={handleClick} style={{color: "white", background:"#0FC1A1", "&:hover": {backgroundColor: "#0A8770"}, width: "100%", minWidth: 150}}>
                                {t('not_found.subtext')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </Container>
    );
}

export default NotFound;
import React, { useEffect, useState } from 'react';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie'
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import {InputLabel, OutlinedInput} from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';



import logo_ebim from "../assets/img/logo_without_bg.png";
import logo_company from "../assets/img/company_logo.png";

import '../config';
import { isAuthenticated } from "../lib/Auth";

import { useSelector } from 'react-redux';
import { Stack } from '@mui/material';
import { formatRole } from '../lib/utils/formatRole';

const languages = [
    { value: 'es', label: 'ES' },
    { value: 'en', label: 'EN' },
    { value: 'pt', label: 'PT' },
];

//hover style for navbar buttons
const hoverStyle = {
    //color: primary,
    backgroundColor: '#687690',
    borderRadius: '5px',
    padding: '5px',
    margin: '5px',
    '&:hover': {
        backgroundColor: '#687690',
        color: '#FFFFFF',
    },
};

function Header() {

    const { t } = useTranslation();

    const logOptions = [t('Home.login'), t('Home.register')];
    const logOptions_urls = ['/login', '/signup'];

    const [language, setLanguage] = useState('');

    const navigate = useNavigate();
    //const loggedIn = isAuthenticated();

    const [isLoggedIn, setLoggedIn] = useState(false);
    const [isAuthChecked, setAuthChecked] = useState(false);

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);


    const userEmail = useSelector(state => state.user.userEmail)
    const accountType = useSelector(state => state.user.userType);
    const userId = useSelector(state => state.user.userID);
    const userCorp = useSelector(state => state.user.userCorp);

    useEffect(() => {
        const savedLanguage = Cookies.get('language');
        if (savedLanguage) {
            setLanguage(savedLanguage);
            i18n.changeLanguage(savedLanguage);
        }
    }, []);


    useEffect(() => {
        const checkAuth = async () => {
            // se llama isAuthenticated y esperamos su resultado
            const authStatus = await isAuthenticated();
            // Actualizamos el estado isLoggedIn con el resultado de isAuthenticated
            setLoggedIn(authStatus);
            // Actualizamos el estado isAuthChecked a true
            setAuthChecked(true);
        };
        checkAuth();
    }, []);


    const menuOptions = [
        {
            name: t('Home.header.pricings'),
            url: 'pricings'
        },
        {
            name: t('Home.header.logout'),
            url: 'logout'
        }
    ]

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLanguageChange = (event) => {
        const selectedLanguage = event.target.value;
        setLanguage(selectedLanguage);
        Cookies.set('language', selectedLanguage, { expires: 180 });
        i18n.changeLanguage(selectedLanguage);
    };

    return (
        <AppBar position='sticky'>
            <Toolbar>
                {/* Botón imagen título */}
                <Link sx={{ ml: "1rem", color: "black", fontWeight: "420", fontSize: "15px", alignItems: "center", display: "flex", textDecoration: "none" }}
                      component={RouterLink}
                      to="/"
                      underline="none"
                      color="black"
                >
                    <img src={logo_ebim} alt="logo_ebim" width={"40px"} />
                    &nbsp;{` E-BIMINDUSTRIAL`}
                    {/* E-BIMINDUSTRIAL */}
                </Link>

                {isAuthChecked && (
                    <>
                        {!isLoggedIn && (
                            <>
                                <Box sx={{ ml: "auto", mr: "0", display: { xs: 'flex', sm: 'none' } }}>
                                    <IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleOpenNavMenu}
                                        color="inherit"
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorElNav}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        open={Boolean(anchorElNav)}
                                        onClose={handleCloseNavMenu}
                                        sx={{
                                            display: { xs: 'block', sm: 'none' },
                                        }}
                                    >
                                        {logOptions.map((page) => (
                                            <MenuItem component={RouterLink} to={logOptions_urls[logOptions.indexOf(page)]} key={page} onClick={handleCloseNavMenu}>
                                                <Typography textAlign="center">{page}</Typography>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Box>

                                <Box sx={{ ml: "auto", mr: "0", display: { xs: 'none', sm: 'flex' } }}>
                                    {logOptions.map((page) => (
                                        <Button
                                            key={page}
                                            onClick={handleCloseNavMenu}
                                            sx={{ my: 2, display: 'block', ml: "0.5rem" }}

                                            color="primary"
                                            component={RouterLink}
                                            to={logOptions_urls[logOptions.indexOf(page)]}
                                            variant="contained"
                                        >
                                            {page}
                                        </Button>
                                    ))}
                                </Box>

                                <Box sx={{ ml: "0.5rem", mr: "0", display: { xs: 'flex', sm: 'flex' } }}>
                                    <FormControl sx={{ m: 1, minWidth: 20 }} size="small">
                                        <Select
                                            value={language}
                                            onChange={handleLanguageChange}
                                            input={<OutlinedInput />}
                                            displayEmpty
                                            sx={{ fontSize: '0.70rem' }}
                                        >
                                            <MenuItem disabled value="">
                                                {t('Home.header.language')}
                                            </MenuItem>
                                            {languages.map((lang) => (
                                                <MenuItem key={lang.value} value={lang.value}>
                                                    {lang.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>

                            </>
                        )}

                        {isLoggedIn && (
                            <>
                                <Box sx={{ flexGrow: 0, marginLeft: 'auto' }} id="user-avatar">
                                    <Stack direction="row" spacing={2}>
                                        <Stack
                                            sx={{
                                                display: {
                                                    xs: 'none',
                                                    sm: 'flex'
                                                }
                                            }}
                                            alignItems="flex-end"
                                        >
                                            <Link component={RouterLink} to="/app/profile" variant='body1'>{userEmail}</Link>
                                            <Typography variant='subtitle1'>{userCorp}</Typography>
                                        </Stack>
                                        <Tooltip title="Open settings">
                                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, marginRight: 'auto' }}>
                                                <Avatar
                                                    src={logo_company}
                                                    sx={{ marginLeft: 'auto' }}
                                                    alt="company_logo"
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                    <Menu
                                        sx={{ mt: '45px' }}
                                        id="menu-appbar"
                                        anchorEl={anchorElUser}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        open={Boolean(anchorElUser)}
                                        onClose={handleCloseUserMenu}
                                    >
                                        {menuOptions.map((option) => (
                                            <MenuItem
                                                component={RouterLink}
                                                to={option.url}
                                                key={option.name} onClick={handleCloseUserMenu}
                                            >
                                                <Typography textAlign="center">{option.name}</Typography>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Box>
                                <Box sx={{ ml: "0.5rem", mr: "0", display: { xs: 'flex', sm: 'flex' } }}>
                                    <FormControl sx={{ m: 1, minWidth: 20 }} size="small">
                                        <Select
                                            value={language}
                                            onChange={handleLanguageChange}
                                            input={<OutlinedInput />}
                                            displayEmpty
                                            sx={{ fontSize: '0.70rem' }}
                                        >
                                            <MenuItem disabled value="">
                                                {t('Home.header.language')}
                                            </MenuItem>
                                            {languages.map((lang) => (
                                                <MenuItem key={lang.value} value={lang.value}>
                                                    {lang.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </>
                        )}
                    </>
                )}
            </Toolbar>
        </AppBar>
    );
}
export default Header;